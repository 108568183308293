import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const ordersApi = createApi({
  reducerPath: 'ordersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Orders'],
  // keepUnusedDataFor: 1,
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({
    getAllOrders: builder.query({
      query: (data) => {
        return {
          url: `orders?page=${data.page}&size=${data.size}&searchid=${data.searchquery ? data.searchquery : data.searchdate}`,
          method: 'GET',
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Orders'],
    }),
    getOrdersById: builder.query({
      query: (data) => ({
        url: `orders/${data.orderId}`,
        method: 'GET',
        headers: {
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.admin,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }),
      // keepUnusedDataFor: 1,
      providesTags: ['Orders'],
    }),

    deleteOrders: builder.mutation({
      query: (data) => {
        return {
          url: `orders/${data.id}`,
          method: 'DELETE',
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Orders'],
    }),

    createOrders: builder.mutation({
      query: (data) => ({
        url: `orders`,
        method: 'POST',
        body: data,
        headers: {
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.admin,
          'Accept': 'application/json',
          'Content-type': 'application/json; charset=UTF-8',
        }
      }),
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Orders'],
    }),

    updateOrders: builder.mutation({
      query: (data) => {
        return {
          url: `orders/${data.id}`,
          method: 'PUT',
          body: data,
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Orders'],
    }),






    createOrderItem: builder.mutation({
      query: (data) => {
        return {
          url: `order_items`,
          method: 'POST',
          body: data,
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Orders'],
    }),

 

    deleteOrderItem: builder.mutation({
      query: (data) => {
        return {
          url: `order_items/${data.id}`,
          method: 'DELETE',
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Orders'],
    }),

    updateOrderItem: builder.mutation({
      query: (data) => {
        return {
          url: `order_items/${data.id}`,
          method: 'PUT',
          body: data,
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Orders'],
    }),


    getOrderItem: builder.query({
      query: (data) => ({
        url: `order_items?page=${data.page}&size=${data.size}&searchid=${data.searchquery}${data.department}`,
        method: 'GET',
        headers: {
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.admin,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }),
      // keepUnusedDataFor: 1,
      providesTags: ['Orders'],
    }),


  }),

})

export const { useGetAllOrdersQuery, useGetOrdersByIdQuery, useGetOrderItemQuery, useDeleteOrdersMutation, useDeleteOrderItemMutation, useCreateOrdersMutation, useCreateOrderItemMutation, useUpdateOrdersMutation, useUpdateOrderItemMutation } = ordersApi