import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import './index.scss';
 
import { useDispatch, useSelector } from "react-redux";

function Reports() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);

  return (
    <div className="Product-style">
 
    </div>
  );
}

export default Reports;
