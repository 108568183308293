import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.css";
import { useGetOrderItemQuery } from '../../services/orders';
import { useGetAllSuppliersQuery } from '../../services/suppliers';
import { useUpdateOrderItemMutation } from '../../services/orders';
import { useSelector } from "react-redux";
import Skeletons from './Skeletons'
import moment from 'moment'
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "../Pagination";
import Select from 'react-select';
import { Menu, Dropdown, Tag } from 'antd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
const KEY = "ADD_PRODUCTS";

function Department() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { name } = useParams();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10
  const getDepartment = new URLSearchParams(location.search).get('name') || null



  const [getSelectedAssignee, setSelectedAssignee] = useState(getDepartment);
  // get assignee data

  function renderList() {
    return [
      { label: 'Visa', value: 'visa' },
      { label: 'Ticket', value: 'ticket' },
      { label: 'Operation', value: 'operation' },
      { label: 'Holidays', value: 'holidays' },
      { label: 'Marketing', value: 'marketing' },
      { label: 'Finance', value: 'finance' }

    ]
  }

  useEffect(() => {
    if (getSelectedAssignee?.value) {
      history.push(`${window.location.pathname}?name=${getSelectedAssignee?.value}`);
    }
    if (getSelectedAssignee === null) {
      history.push(`${window.location.pathname}`);
    }
  }, [getSelectedAssignee?.value]);

  function removeAssigneeQuery() {
    history.push(`${window.location.pathname}`);
  }

  let departmentGet
  if (getDepartment === null) {
    departmentGet = '';
  } else {
    departmentGet = `&name=${getDepartment}`
  }


  const orderItemInfo = useGetOrderItemQuery({
    department: departmentGet,
    page: page,
    size: limit,
    searchquery: getSearchQuery,
    userId: userId,
    userToken: userToken,
    admin: admin
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const orderItems = orderItemInfo.data && orderItemInfo.data?.order_items;
  const pagination = orderItemInfo.data && orderItemInfo.data?.pagination;


  const suppliersData = useGetAllSuppliersQuery({ page: 1, size: 140, userId: userId, userToken: userToken, admin: admin, searchquery: getSearchQuery, }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const suppliers = suppliersData?.data && suppliersData?.data?.suppliers;


  const [selectedOption, setSelectedOption] = useState(null);
  const [getOrderItemId, setOrderItemId] = useState();
  const [getState, setState] = useState('');
  const [getBuyPrice, setBuyPrice] = useState('');
  const [getState2, setState2] = useState('');
  function renderList() {
    return (suppliers?.map(data => ({ label: data.name, value: data.id })))
  }
  const [getNewOrderItemModal, setNewOrderItemModal] = useState(false);
  function closeNewOrderItemModal() {
    setNewOrderItemModal(false)
  }
  function createNewOrderItem(data) {
    setNewOrderItemModal(data.status)
    setOrderItemId(data.order_item_id)
    setState(data.state)
    setState2(data.state)
  }


  const [updatePost, updateresult] = useUpdateOrderItemMutation()
  useEffect(() => {
    if (updateresult?.isSuccess) {
      setNewOrderItemModal(false)
    }
  }, [updateresult]);



  function displayState(data) {
    let variant;
    let color;
    let name;
    if (data === 'fulfilled') {
      variant = '#7fc687'
      name = 'Fulfilled'
    }
    else if (data === 'pending') {
      variant = '#ffd577'
      name = 'Pending'
      color = '#444'
    }
    else if (data === 'cancelled') {
      variant = '#ea8087'
      name = 'Cancelled'
    } else {
      variant = 'warning'
      name = '#ffd577'
      color = '#444'
    }
    return (
      <>
        <Tag className="booksTag" color={variant} style={{ color: color, margin: 0 }}> {name} </Tag>
      </>
    )
  }

  const customStyles = {
    control: styles => ({
      ...styles,
      width: 'auto'
    }),
    option: styles => ({
      ...styles,
      width: 'auto'
    }),
    menu: styles => ({
      ...styles,
      width: 'auto'
    })

  };

  return (
    <div className="Order-style">

      <Modal className="Order-New_modal" show={getNewOrderItemModal} onHide={closeNewOrderItemModal} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Add Supplier</label>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={renderList()}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>State</label>
                <select className="form-control dlrdflInput" onChange={(e) => setState(e.target.value)}>
                  <option selected value={getState ? getState : 'pending'}>{getState ? getState : 'pending'}</option>
                  <option value={'pending'}>Pending</option>
                  <option value={'fulfilled'}>Fulfilled</option>
                  <option value={'cancelled'}>Cancelled</option>

                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Buy Price</label>
                <input type="number" required
                  className="form-control input_style" value={getBuyPrice} onChange={(e) => setBuyPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                {updateresult?.isLoading ?
                  <Button variant="info" size="sm" className="button_style" disabled>updating</Button> :
                  <Button variant="info" size="sm" className="button_style"
                    onClick={() => { (selectedOption) && updatePost({ supplier_id: selectedOption?.value, id: getOrderItemId, state: getState, buy_price: getBuyPrice, add_supplier: 'add_supplier', userId: userId, userToken: userToken, admin: admin }) }}>Update</Button>}

              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>



      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="Order-Top_navbar">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4>Department: &nbsp;<span style={{ color: '#a1a1a1' }}>{name}</span></h4>
                {(orderItemInfo?.isFetching) && (!orderItemInfo?.isLoading) &&
                  <div className="bouncing-loader" style={{ paddingLeft: '15px', paddingTop: '4px' }}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                }
              </div>
              <div className="dFlex center nowrap">


                <div className="df ai">
                  {getDepartment &&
                    <div className="mr20" style={{ border: '1px solid #eee', background: '#fff', padding: '0px 10px 2px 10px', borderRadius: '4px', display: 'flex', alignItems: 'center', position: 'relative' }}>
                      <small>
                        {getDepartment}
                        <RemoveCircleIcon onClick={removeAssigneeQuery} style={{ color: '#e66565', position: 'absolute', right: '-12px', top: '-8px', cursor: 'pointer' }} />
                      </small>
                    </div>
                  }
                  <Select
                    className="mr10"
                    isClearable
                    styles={customStyles}
                    isLoading={orderItemInfo?.isFetching ? true : false}
                    onChange={setSelectedAssignee}
                    options={[
                      { label: 'Select', value: '', isDisabled: true },
                      { label: 'Visa', value: 'visa', isDisabled: getDepartment === 'visa' ? true : false },
                      { label: 'Ticket', value: 'ticket', isDisabled: getDepartment === 'ticket' ? true : false },
                      { label: 'Operation', value: 'operation', isDisabled: getDepartment === 'operation' ? true : false },
                      { label: 'Holidays', value: 'holidays', isDisabled: getDepartment === 'holidays' ? true : false },
                      { label: 'Marketing', value: 'marketing', isDisabled: getDepartment === 'marketing' ? true : false },
                      { label: 'Finance', value: 'finance', isDisabled: getDepartment === 'finance' ? true : false }

                    ]}
                  />

                </div> 
                <div className="table_search_card">
                  <input className="form-control table_search" placeholder="Search..." onChange={(e) => searchQuery(e.target.value)} />
                  {orderItemInfo?.isFetching &&
                        <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', position: 'absolute', top: '12px', right: '40px', color: '#cbcbcb' }} animation="border" />
                      }
                  <SearchIcon className="table_search_icon" />
                </div>

              </div>
            </div>

            <div className="Order-Pagination">
              <Pagination page={page} limit={limit} pagination={pagination} departmentGet={departmentGet} />
            </div>

            <div className="Order-Table_view">
              <div className="Order-Table_card">
                <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                  <div className="Order-Table_item heading fw700 tac" style={{width: '1px'}}>#</div>
                  <div className="Order-Table_item heading fw700">Order</div>

                  <div className="Order-Table_item heading fw700" >Item</div>
                 
                    <div className="Order-Table_item heading fw700">Dep.</div>
                  
                  <div className="Order-Table_item heading fw700">Supplier</div>
                  <div className="Order-Table_item heading fw700">Nationality</div>
                  <div className="Order-Table_item heading fw700">Sale</div>
                  <div className="Order-Table_item heading fw700">Buy</div>
                  <div className="Order-Table_item heading fw700">User</div>
                  <div className="Order-Table_item heading fw700">Date</div>
                  <div className="Order-Table_item heading fw700 sticky-column tar">Manage</div>
                </div>
                {orderItemInfo?.isLoading &&
                  <Skeletons />
                }
                {orderItems?.map((item, i) =>
                  <div className="Order-Table_subcard">
                    <div className="Order-Table_item text tac">
                      <a onClick={e => e.preventDefault()}>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? 'long-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={() => createNewOrderItem({ status: true, order_item_id: item.id, state: item.state })}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </a>
                    </div>
                    <div className="Order-Table_item text">{item.order_id}</div>
                    <div className="Order-Table_item text">
                      <div>{item.product?.product}</div>
                      <div><small>{item.item}</small></div>
                      <div><small>{item.description}</small></div>
                    </div>
                   
                      <div className="Order-Table_item text">{item.department}</div>
                   
                    <div className="Order-Table_item text">{item.supplier?.name}</div>
                    <div className="Order-Table_item text">{item.order?.nationality}</div>
                    <div className="Order-Table_item text">{Number(item.price) + Number(item.addon)}</div>
                    <div className="Order-Table_item text">{item.buy_price}</div>
                    <div className="Order-Table_item text">{item.user?.name}</div>
                    <div className="Order-Table_item text">
                      {moment(item.createdAt).local().format("MMM Do YY")}
                    </div>
                    <div className="Order-Table_item text sticky-column">
                      <div className="dFlex center nowrap jcr">
                        {displayState(item.state)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {orderItems?.length === 0 &&
                <h1 className="Order-no_data_found">Not Data Found!</h1>
              }
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Department;
