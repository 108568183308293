import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const sessionsApi = createApi({
 reducerPath: 'sessionsApi',
 baseQuery: fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
 }),

 tagTypes: [ 'Session' ],
  // keepUnusedDataFor: 1,
  refetchOnMountOrArgChange: 1,
 endpoints: (builder) => ({

  createSessions: builder.mutation({
    query: (data) => ({ 
      url: `sessions`,
       method: 'POST',
       body: data,
       headers: {
         'Accept': 'application/json',
         'Content-type': 'application/json; charset=UTF-8',
       }
    }),
    // keepUnusedDataFor: 1,
     invalidatesTags: [ 'Session' ],
  }),
 }),

})

export const { useCreateSessionsMutation } = sessionsApi