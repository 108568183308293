import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useHistory } from "react-router-dom";
import { Button, Spinner } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import './index.scss';
import { addToCart } from "../../reducers/authReducer";
import { useCreateSessionsMutation } from '../../services/session';
import { useDispatch, useSelector } from "react-redux";
function Login() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const history = useHistory();

  const [loginUser, loginresult] = useCreateSessionsMutation()
  const loginData = loginresult?.data;

 
  const loginError = loginresult?.error?.data?.error;

  useEffect(() => {
    if (loginresult?.isSuccess) {
      dispatch(addToCart({
        userId: loginData?.id,
        userToken: loginData?.token,
        userEmail: loginData?.email,
        userName: loginData?.name,
        status: loginData?.status,
        admin: loginData?.admin,
      }));
      // history.push('/dashboard');
    }
  }, [loginresult?.isSuccess]);



  return (
    <div className="Login-page">
      {userToken && userId && <Redirect to="/" />}
      <div className="container">
        <div className="row loginRow">
          <div className="rowBox">
            <div className="loginHeader">
              <h2><img src='/seven.png' width={'150px'} /></h2>
              <h4>LOGIN</h4>
            </div>
            <div>
              {loginError && <h6 className="alert alert-danger textMsgV"> {loginError}</h6>}
            </div>
            <form onSubmit={handleSubmit(loginUser)}>
              <div className="form-group">
                <label>Email ID</label>
                <input className="form-control" type="email" id="email"
                  aria-invalid={errors.email ? "true" : "false"}
                  {...register('email', { required: true })}
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control dlrdflInput"
                  id="password"
                  aria-invalid={errors.password ? "true" : "false"}
                  {...register('password', { required: true, minLength: 8, maxLength: 16 })} />
                {errors.password && errors.password.type === "required" && <span style={{ color: "red", fontSize: "12px" }}>required*</span>}
                {errors.password && errors.password.type === "maxLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be maximum 16 digits</span>}
                {errors.password && errors.password.type === "minLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be minimum 8 digits</span>}
              </div>
              {loginresult.isLoading ? <Button style={{ width: '100%' }} variant="info" disabled>Loading...</Button> : <Button style={{ width: '100%' }} variant="info" type="submit">Login</Button>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
