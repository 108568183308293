import React, { useState, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Dashboard from './components/Dashboard';
import Users from './components/Users';
import ManageOrder from './components/ManageOrder';
import Login from './components/Login';
import Profile from './components/Profile';
import Products from './components/Products';
import SalesOrder from './components/SalesOrder';
import Accounts from './components/Accounts';
import Reports from './components/Reports';
import Posts from './components/Posts';
import Suppliers from './components/Suppliers';
import Supplier from './components/Supplier';
import Operations from './components/Operations';
import Private from './Private';
import NotFound from './NotFound';
import './App.css';


function App() {
  return (
    <div className="App">

      <Router>
        <Switch>
          <Route exact path="/">
            <Private cmp={Dashboard} />
          </Route>
          <Route exact path="/products">
            <Private cmp={Products} />
          </Route>
          <Route exact path="/sales">
            <Private cmp={SalesOrder} />
          </Route>
          <Route exact path="/accounts">
            <Private cmp={Accounts} />
          </Route>
          <Route exact path="/reports">
            <Private cmp={Reports} />
          </Route>
          <Route exact path="/posts">
            <Private cmp={Posts} />
          </Route>
          <Route exact path="/suppliers">
            <Private cmp={Suppliers} />
          </Route>
          <Route exact path="/supplier/:getId">
            <Private cmp={Supplier} />
          </Route>
          <Route exact path="/operations">
            <Private cmp={Operations} />
          </Route>
          <Route exact path="/order/:orderId">
            <Private cmp={ManageOrder} />
          </Route>
          <Route exact path="/users">
            <Private cmp={Users} />
          </Route>
          <Route exact path="/profile">
            <Private cmp={Profile} />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route>
            <Private cmp={NotFound} />
          </Route>
        </Switch>

      </Router>


    </div>
  );
}

export default App;
