import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import { message, DatePicker, Space, Tag } from "antd";
import "antd/dist/antd.css";
import { useGetAllOrdersQuery, useGetOrdersByIdQuery, useDeleteOrdersMutation, useCreateOrdersMutation, useUpdateOrdersMutation } from '../../services/orders';
import { useDispatch, useSelector } from "react-redux";
import { useGetAllNationalityQuery } from '../../services/nationalities';
import Pagination from "../Pagination";
import MenuItem from '@mui/material/MenuItem';
import Skeletons from './Skeletons'
import moment from 'moment'
import { Menu, Dropdown } from 'antd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
const KEY = "ADD_STUDENT";

function SalesOrder() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);

  const dispatch = useDispatch()
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const location = useLocation();


  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const [getSetSearctByData, setSearctByData] = useState("")
  const [getSearchQuery, searchQuery] = useState("")
  function onChange(date, dateString) {
    setSearctByData(dateString);
  }


  const ordersData = useGetAllOrdersQuery({ page: page, size: limit, searchquery: getSearchQuery, searchdate: getSetSearctByData, userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const orders = ordersData?.data && ordersData?.data?.orders;
  const pagination = ordersData?.data && ordersData?.data?.pagination;

  const nationalityList = useGetAllNationalityQuery({ count: 1 }, { refetchOnMountOrArgChange: true })
  const getNationalitiesData = nationalityList.data && nationalityList.data;


  const [deleteCampaign, deleteresult] = useDeleteOrdersMutation()
  const [createCampaign, createresult] = useCreateOrdersMutation()




  const [getEditShow, setEditShow] = useState(false);
  const [getOrderName, setOrderName] = useState("")
  const [getOrderMobile, setOrderMobile] = useState("")
  const [getOrderEmail, setOrderEmail] = useState("")
  const [getOrderAddress, setOrderAddress] = useState("")
  const [getOrderNationality, setOrderNationality] = useState("")
  const [getNumber, setNumber] = useState("")
  const [getOrderId, setOrderId] = useState("")
  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(data.status)
    setOrderName(data.orderName)
    setOrderMobile(data.orderMobile)
    setOrderEmail(data.orderEmail)
    setOrderAddress(data.orderAddress)
    setOrderNationality(data.orderNationality)
    setNumber(data.number)
    setOrderId(data.orderId)
  }

  const updateData = {
    "id": getOrderId,
    "name": getOrderName,
    "mobile": getOrderMobile,
    "email": getOrderEmail,
    "address": getOrderAddress,
    "nationality": getOrderNationality,
    "number": getNumber,
    "userId": userId,
    "userToken": userToken,
    "admin": admin
  }
  const [updatePost, updateresult] = useUpdateOrdersMutation()


  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
    }
  }, [updateresult]);

  const [getNewOrderModal, setNewOrderModal] = useState(false);
  function closeNewOrderModal() {
    setNewOrderModal(false)
  }
  function createNewOrder() {
    setNewOrderModal(true)
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      setNewOrderModal(false)
      setOrderName('')
      setOrderMobile('')
      setOrderEmail('')
      setOrderAddress('')
      setOrderNationality('')
      setOrderId('')
    }
  }, [createresult]);


  function displayStates(data) {
    if (data === 'paid') {
      return (
        <Tag className="booksTag" color="#7fc687"> {'Closed'} </Tag>
      )
    }
    else if (data === 'pending') {
      return <Tag className="booksTag" color="#ffd577" style={{ color: '#444' }}> {'Pending'} </Tag>
    }
    else if (data === null) {
      return <Tag className="booksTag" color="#ffd577" style={{ color: '#444' }}> {'Pending'} </Tag>
    }
    else {
      return <Tag className="booksTag" color="#ffd577" style={{ color: '#444' }}> {'Pending'} </Tag>

    }
  }




  return (
    <div className="Order-style">

      <Modal className="Order-New_modal" show={getNewOrderModal} onHide={closeNewOrderModal} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Name/Company</label>
                <input type="text" className="form-control input_style" value={getOrderName}
                  onChange={(e) => setOrderName(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Mobile</label>
                <input type="number" className="form-control input_style" value={getOrderMobile}
                  onChange={(e) => setOrderMobile(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Email</label>
                <input type="email" className="form-control input_style" value={getOrderEmail}
                  onChange={(e) => setOrderEmail(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Address</label>
                <input type="text" className="form-control input_style" value={getOrderAddress}
                  onChange={(e) => setOrderAddress(e.target.value)} />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label>Nationalities</label>
                <select className="form-control input_style" onChange={(e) => setOrderNationality(e.target.value)}>
                  <option selected value={getOrderNationality ? getOrderNationality : ''}>{getOrderNationality ? getOrderNationality : 'Select'}</option>
                  {getNationalitiesData && getNationalitiesData.nationalities.map((item, i) =>
                    <option value={`${item.name}`}>{item.name}</option>
                  )}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Number</label>
                <input type="text" className="form-control input_style" value={getNumber}
                  onChange={(e) => setNumber(e.target.value)} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                {createresult?.isLoading ? <Button variant="info" size="sm" className="button_style" disabled>Creating</Button> : <Button variant="info" size="sm" className="button_style" onClick={() => { (getOrderName) && (getOrderMobile) && (getOrderEmail) && (getOrderAddress) && (getOrderNationality) && createCampaign(updateData) }}>Create</Button>}

              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="Order-New_modal" show={getEditShow} onHide={handleCloseEdit} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <section><span className="loader-72"> </span></section>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Name/Company</label>
                <input type="text" className="form-control input_style" value={getOrderName}
                  onChange={(e) => setOrderName(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Mobile</label>
                <input type="number" className="form-control input_style" value={getOrderMobile}
                  onChange={(e) => setOrderMobile(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Email</label>
                <input type="email" className="form-control input_style" value={getOrderEmail}
                  onChange={(e) => setOrderEmail(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Address</label>
                <input type="text" className="form-control input_style" value={getOrderAddress}
                  onChange={(e) => setOrderAddress(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Nationalities</label>
                <select className="form-control input_style" onChange={(e) => setOrderNationality(e.target.value)}>
                  <option selected value={getOrderNationality}>{getOrderNationality}</option>
                  {getNationalitiesData && getNationalitiesData.nationalities.map((item, i) =>
                    <option value={`${item.name}`}>{item.name}</option>
                  )}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Number</label>
                <input type="text" className="form-control input_style" value={getNumber}
                  onChange={(e) => setNumber(e.target.value)} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                {updateresult?.isLoading ? <Button variant="info" size="sm" className="button_style" disabled>Updating</Button> : <Button variant="info" size="sm" className="button_style" onClick={() => { (getOrderName) && (getOrderMobile) && (getOrderEmail) && (getOrderAddress) && (getOrderNationality) && updatePost(updateData) }}>Update</Button>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* {isLoading ? <div className="loadingBorder">&nbsp;</div> : ''} */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="Order-Top_navbar">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4>Sales Order</h4>
                {(ordersData?.isFetching) && (!ordersData?.isLoading) &&
                  <div className="bouncing-loader" style={{ paddingLeft: '15px', paddingTop: '4px' }}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                }
              </div>
              <div className="dFlex center nowrap">
                <Button className="mr10 st_button" onClick={() => createNewOrder(true)}>
                  Add New
                </Button>
                <div className="table_search_card">
                  <input className="form-control table_search" placeholder="Search..." onChange={(e) => searchQuery(e.target.value)} />
                  {ordersData?.isFetching &&
                    <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', position: 'absolute', top: '12px', right: '40px', color: '#cbcbcb' }} animation="border" />
                  }
                  <SearchIcon className="table_search_icon" />
                </div>
                {/* <Space direction="vertical">
                  <DatePicker onChange={onChange} className="datePicker" />
                </Space> */}
              </div>
            </div>

            <div className="Order-Pagination">
              <Pagination page={page} limit={limit} pagination={pagination} />
            </div>



            <div className="Order-Table_view">
              <div className="Order-Table_card">
                <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                  <div className="Order-Table_item heading fw700 tac" style={{width: '1px'}}>#</div>
                  <div className="Order-Table_item heading fw700">ID</div>
                  <div className="Order-Table_item heading fw700">Name/Company</div>
                  <div className="Order-Table_item heading fw700">Email</div>
                  <div className="Order-Table_item heading fw700">Mobile</div>
                  <div className="Order-Table_item heading fw700">Nationality</div>
                  <div className="Order-Table_item heading fw700">Number</div>
                  <div className="Order-Table_item heading fw700">Date</div>
                  <div className="Order-Table_item heading sticky-column fw700 tar">Status</div>
                </div>
                {ordersData?.isLoading &&
                  <Skeletons />
                }
                {orders?.map((item, i) =>
                  <div className="Order-Table_subcard">
                    <div className="Order-Table_item text tac" style={{width: '1px'}}>
                      <Dropdown className="Header-waller-connect2" overlay={
                        <Menu className="Header-dropdown-menu">
                          <MenuItem key={i} onClick={() => handleOpenEdit({
                            "status": true,
                            "orderName": item.name,
                            "orderMobile": item.mobile,
                            "orderEmail": item.email,
                            "orderAddress": item.address,
                            "orderNationality": item.nationality,
                            "number": item.number,
                            "orderId": item.id
                          })}> Edit </MenuItem>
                          {item.status !== 1 &&
                            <MenuItem key={i} onClick={() => deleteCampaign({
                              "id": item.id, "userId": userId,
                              "userToken": userToken,
                              "admin": admin
                            })}> Delete </MenuItem>
                          }
                        </Menu>
                      } trigger={['click']}>
                        <a onClick={e => e.preventDefault()}>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </a>
                      </Dropdown>
                    </div>
                    <div className="Order-Table_item text">
                      {item.id}
                    </div>
                    <div className="Order-Table_item text">
                      <Link to={`order/${item.id}`}>
                        {item.name}
                      </Link>
                    </div>
                    <div className="Order-Table_item text">{item.email}</div>
                    <div className="Order-Table_item text">{item.mobile}</div>
                    <div className="Order-Table_item text">{item.nationality}</div>
                    <div className="Order-Table_item text">{item.number}</div>
                    <div className="Order-Table_item text">
                      {moment(item.createdAt).local().format("MMM Do YY")}
                    </div>
                    <div className="Order-Table_item text sticky-column">
                      <div className="dFlex center nowrap jcr">
                        {displayStates(item.state)}
                        {item.status === 1 &&
                          <Tag className="booksTag" color="#ea8087">
                            {'Deleted'}
                          </Tag>
                        }
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {orders?.length === 0 &&
                <h1 className="Order-no_data_found">Not Data Found!</h1>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesOrder;
