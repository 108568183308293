import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.css";
import { useGetAllSuppliersQuery, useDeleteSuppliersMutation, useCreateSuppliersMutation, useUpdateSuppliersMutation } from '../../services/suppliers';
import { useSelector } from "react-redux";
import Skeletons from './Skeletons'
import moment from 'moment'
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "../Pagination";
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import { message, Tag } from "antd";
const KEY = "ADD_PRODUCTS";
function Suppliers() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const suppliersData = useGetAllSuppliersQuery({ page: page, size: limit, userId: userId, userToken: userToken, admin: admin, searchquery: getSearchQuery, }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const suppliers = suppliersData?.data && suppliersData?.data?.suppliers;
  const pagination = suppliersData?.data && suppliersData?.data?.pagination;

  const [deleteList, deleteresult] = useDeleteSuppliersMutation()
  const [createNew, createresult] = useCreateSuppliersMutation()

  const [show, setShow] = useState(false);
  const [getEditShow, setEditShow] = useState(false);
  const [getName, setName] = useState("")
  const [getEmail, setEmail] = useState("")
  const [getPhone, setPhone] = useState("")
  const [getAddress, setAddress] = useState("")
  const [getId, setId] = useState("")
  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(data.status)
    setName(data.name)
    setEmail(data.email)
    setPhone(data.phone)
    setAddress(data.address)
    setId(data.id)
  }

  const updateData = {
    id: getId,
    name: getName,
    phone: getPhone,
    email: getEmail,
    address: getAddress,
    userId: userId, userToken: userToken, admin: admin
  }
  const [updatePost, updateresult] = useUpdateSuppliersMutation()

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
    }
    if (createresult?.isSuccess) {
      setShow(false)
    }
  }, [updateresult, createresult]);

  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }



  return (
    <div className="Order-style">
      <Modal className="new_Modal" show={show} onHide={handleClose} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(createNew)}>
            <div className="row">
              <input type="hidden" id="userId" defaultValue={userId} aria-invalid={errors.userId ? "true" : "false"}
                {...register('userId', { required: false })} />
              <input type="hidden" id="userToken" defaultValue={userToken} aria-invalid={errors.userToken ? "true" : "false"}
                {...register('userToken', { required: false })} />
              <input type="hidden" id="admin" defaultValue={admin} aria-invalid={errors.admin ? "true" : "false"}
                {...register('admin', { required: false })} />
              <div className="col-md-12">
                <div className="form-group">
                  <label>Name</label>
                  <input type="text" className="form-control dlrdflInput" id="name"
                    aria-invalid={errors.name ? "true" : "false"}
                    {...register('name', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Email</label>
                  <input type="text" className="form-control dlrdflInput" id="email"
                    aria-invalid={errors.email ? "true" : "false"}
                    {...register('email', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Phone</label>
                  <input type="text" className="form-control dlrdflInput" id="phone"
                    aria-invalid={errors.phone ? "true" : "false"}
                    {...register('phone', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Address</label>
                  <input type="text" className="form-control dlrdflInput" id="address"
                    aria-invalid={errors.address ? "true" : "false"}
                    {...register('address', { required: true })} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  {createresult?.isLoading ? <Button variant="info" size="sm" className="hghtbtn" disabled>Creating</Button> : <Button variant="info" size="sm" className="hghtbtn" type="submit">Create</Button>}

                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal className="new_Modal" show={getEditShow} onHide={handleCloseEdit} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Name</label>
                <input type="text" className="form-control dlrdflInput" value={getName}
                  onChange={(e) => setName(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Email</label>
                <input type="text" className="form-control dlrdflInput" value={getEmail}
                  onChange={(e) => setEmail(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Phone</label>
                <input type="text" className="form-control dlrdflInput" value={getPhone}
                  onChange={(e) => setPhone(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Address</label>
                <input type="text" className="form-control dlrdflInput" value={getAddress}
                  onChange={(e) => setAddress(e.target.value)} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                {updateresult?.isLoading ? <Button variant="info" size="sm" className="hghtbtn" disabled>Updating</Button> : <Button variant="info" size="sm" className="hghtbtn" onClick={() => { updatePost(updateData) }}>Update</Button>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="Order-Top_navbar">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4>Suppliers List</h4>
                {(suppliersData?.isFetching) && (!suppliersData?.isLoading) &&
                  <div className="bouncing-loader" style={{ paddingLeft: '15px', paddingTop: '4px' }}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                }
              </div>
              <div className="dFlex center nowrap">
                <Button className="mr10 st_button" onClick={() => handleOpen(true)}>
                  Add New
                </Button>
                <div className="table_search_card">
                  <input className="form-control table_search" placeholder="Search..." onChange={(e) => searchQuery(e.target.value)} />
                  <SearchIcon className="table_search_icon" />
                </div>

              </div>
            </div>

            <div className="Order-Pagination">
              <Pagination page={page} limit={limit} pagination={pagination} />
            </div>

            <div className="Order-Table_view">
              <div className="Order-Table_card">
                <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                  <div className="Order-Table_item heading fw700">Name</div>
                  <div className="Order-Table_item heading fw700">Phone</div>
                  <div className="Order-Table_item heading fw700">Email</div>
                  <div className="Order-Table_item heading fw700">Address</div>
                  <div className="Order-Table_item heading fw700">Date</div>
                  <div className="Order-Table_item heading fw700 sticky-column tar">Manage</div>
                </div>
                {suppliersData?.isLoading &&
                  <Skeletons />
                }
                {suppliers?.map((item, i) =>
                  <div className="Order-Table_subcard">
                    <div className="Order-Table_item text">
                      <Link to={`/supplier/${item.id}`} >
                        {item.name}
                      </Link>
                    </div>
                    <div className="Order-Table_item text">{item.phone}</div>
                    <div className="Order-Table_item text">{item.email}</div>
                    <div className="Order-Table_item text">{item.address}</div>
                    <div className="Order-Table_item text">
                      {moment(item.createdAt).local().format("MMM Do YY, h:mm")}
                    </div>
                    <div className="Order-Table_item text sticky-column">
                      <div className="dFlex center nowrap jcr">

                        <a className='mr10' onClick={() => handleOpenEdit({
                          "status": true,
                          "name": item.name,
                          "phone": item.phone,
                          "email": item.email,
                          "address": item.address,
                          "id": item.id
                        })}>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                          >
                            <EditFilled style={{ fontSize: '18px' }} />
                          </IconButton>
                        </a>
                        {item.status === 0 ? 
                          <small className="dFlex">
                            {deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id ?
                              <div className="bouncing-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              :
                              <a className='mr10' onClick={() => deleteList({ activate: 'activate', id: item.id, userId: userId, userToken: userToken, admin: admin })}>
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={open ? 'long-menu' : undefined}
                                  aria-expanded={open ? 'true' : undefined}
                                  aria-haspopup="true"
                                >
                                  <DoDisturbOnIcon style={{ fontSize: '22px', color: '#ea8087', }} />
                                </IconButton>
                              </a>
                            }
                          </small> :
                           <small className="dFlex">
                           {deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id ?
                             <div className="bouncing-loader">
                               <div></div>
                               <div></div>
                               <div></div>
                             </div>
                             :
                             <a className='mr10' onClick={() => deleteList({ deactivate: 'deactivate', id: item.id, userId: userId, userToken: userToken, admin: admin })}>
                               <IconButton
                                 aria-label="more"
                                 id="long-button"
                                 aria-controls={open ? 'long-menu' : undefined}
                                 aria-expanded={open ? 'true' : undefined}
                                 aria-haspopup="true"
                               >
                                 <CheckCircleIcon style={{ fontSize: '22px', color: '#7fc687', }} />
                               </IconButton>
                             </a>
                           }
                         </small>
                        }

                        {/* <Tag className="booksTag" color={item.status === 0 ? '#ea8087' : '#7fc687'} style={{ margin: 0 }}>
                          {item.status === 0 ? 'Deleted' : 'Active'}
                        </Tag> */}
                      </div>
                    </div>
                  </div>
                )}


              </div>
              {suppliers?.length === 0 &&
                <h1 className="Order-no_data_found">Not Data Found!</h1>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Suppliers;
