import React, { useState, useEffect, Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  Redirect,
  useLocation,
} from "react-router-dom";
import {
  Navbar,
} from "react-bootstrap";
import NotificationsIcon from "@material-ui/icons/Notifications";
import "./index.scss";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { useDispatch, useSelector } from "react-redux";
import { removeToCart } from "../../reducers/authReducer";
import { useGetAllDepartmentQuery } from '../../services/departments';
import { Menu, Dropdown } from 'antd';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import PagesIcon from '@mui/icons-material/Pages';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
const { SubMenu } = Menu;
const KEY = "ADD_PRODUCTS";

function HeaderMenu(props) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  function handleClick(e) {
  }
  const history = useHistory();
  const location = useLocation();

  function userLogout() {
    dispatch(removeToCart());
    history.push("/login");
  }



  const departmentList = useGetAllDepartmentQuery({ userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const getDepartmentData = departmentList.data && departmentList.data;

  const [collapsed, setStatesss] = useState(false)
  function toggleCollapsed() {
    setStatesss(!collapsed)
  };




  const concernedElements = document.querySelector(".accountModal");
  const concernedElement = document.querySelector(".click-account");

  document.addEventListener("mousedown", (event) => {
    if (concernedElement && concernedElement.contains(event.target) || concernedElements && concernedElements.contains(event.target)) {
      setShow(true)
    } else {
      setShow(false)
    }
  });

  const menu = (
    <Menu className="Header-dropdown-menu">
      <Menu.Item key="0">
        <Link to="/profile">Profile</Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to="/profile">Settings</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/profile">Change Password</Link>
      </Menu.Item>
      <br /><br />
      <Menu.Item key="3" onClick={userLogout}>
        <Link to={'#'}>Sign out</Link>
      </Menu.Item>
    </Menu>
  );

  // let storeNamea = store_name ? userName.split(" ")[0] : null;
  const userNamea = userName ? userName.slice(0, 12) : null;

  const [getMobileClick, setMobileClick] = useState(true)
  function mobileMenuClick(data) {
    setMobileClick(data)
  }



  return (
    <div className="headerMenu">

      <Menu onClick={handleClick} mode="vertical"
        inlineCollapsed={props.collapsed} className={`sideMenuN ${getMobileClick && 'classNone'}`}>

        <div className={`navTophdng strNumnv logoHeader ${props.collapsed && 'navTophdngcollapsed'} ${getMobileClick && 'classRelative'}`}>
          <span className="nameColor">
            <span className={`header_top-heading ${props.collapsed && 'hideHeaderName'}`}>
              Seven Tourism
              {/* <img src='/newwwwwhite.png' width={'38px'} /> */}
            </span>
            <CloseIcon className="desktop_hide" onClick={(() => mobileMenuClick(true))} />
            <span style={{ color: 'rgb(161, 161, 161)', fontSize: '24px' }} className={`syncIcon mobile_hide ${props.collapsed && 'syncColor'}`} onClick={() => props.toggleCollapsed({ toggleCollapsed })}>
              {React.createElement(props.collapsed ? SyncAltIcon : SyncAltIcon)}
            </span>
          </span>
        </div>


        <Menu.Item className="menuColor dFlex" key="11" icon={<DashboardIcon />}>
          <Link to={{ pathname: "/", state: { from: "root" } }}>
            Dashboard
          </Link>
        </Menu.Item>

        <Menu.Item className="menuColor dFlex" key="49" icon={<PagesIcon />}>
          <Link to={{ pathname: "/suppliers", state: { from: "root" } }}>
            Suppliers
          </Link>
        </Menu.Item>
        <Menu.Item className="menuColor dFlex" key="48" icon={<PagesIcon />}>
          <Link to={{ pathname: "/products", state: { from: "root" } }}>
            Products
          </Link>
        </Menu.Item>
        <Menu.Item className="menuColor dFlex" key="33" icon={<PagesIcon />}>
          <Link to={{ pathname: "/sales", state: { from: "root" } }}>
            Sales Orders
          </Link>
        </Menu.Item>
        <Menu.Item className="menuColor dFlex" key="55" icon={<PagesIcon />}>
          <Link to={{ pathname: "/operations", state: { from: "root" } }}>
            Operations
          </Link>
        </Menu.Item>


        {/* <SubMenu key="sub1" className="menuColor" icon={<PagesIcon />} title="Operations">
          <Menu.Item className="subMenuColor">
            <Link to={{ pathname: `/department/${'all'}`, state: { from: "root" } }}>
              <div style={{ textTransform: 'capitalize' }}>All</div>
            </Link>
          </Menu.Item>
          {getDepartmentData && getDepartmentData.departments.map((item, i) =>
            <Menu.Item className="subMenuColor">
              <Link to={{ pathname: `/department/${item.name}`, state: { from: "root" } }}>
                <div style={{ textTransform: 'capitalize' }}>{item.name}</div>
              </Link>
            </Menu.Item>
          )}
        </SubMenu> */}
        <Menu.Item className="menuColor dFlex" key="52" icon={<PagesIcon />}>
          <Link to={{ pathname: "/users", state: { from: "root" } }}>
            Users
          </Link>
        </Menu.Item>
        <Menu.Item className="menuColor dFlex" key="45" icon={<PagesIcon />}>
          <Link to={{ pathname: "/accounts", state: { from: "root" } }}>
            Accounts
          </Link>
        </Menu.Item>
        <Menu.Item className="menuColor dFlex" key="46" icon={<PagesIcon />}>
          <Link to={{ pathname: "/reports", state: { from: "root" } }}>
            Reports
          </Link>
        </Menu.Item>
        <Menu.Item className="menuColor dFlex" key="54" icon={<PagesIcon />}>
          <Link to={{ pathname: "/posts", state: { from: "root" } }}>
            Posts
          </Link>
        </Menu.Item>

      </Menu>
      <Navbar className={`topNavBar ${props.collapsed && 'menuCollapsed'}`} bg="white" expand="lg">
        <div className="addAllIconCard">
          {/* <img src='/newwwwsssss.png' width={'40px'} /> */}
          <MenuIcon style={{ marginRight: '15px' }} className="desktop_hide" onClick={(() => mobileMenuClick(false))} />
          <div style={{ fontSize: '24px', color: 'rgb(161, 161, 161)' }}>{userNamea}</div>
        </div>
        <div className="headerRightCard">
          {/* <NotificationsIcon style={{ fontSize: '32px', color: '#21294563' }} className="mr20" /> */}
          <Dropdown className="Header-waller-connect2" overlay={menu} trigger={['click']}>
            <a onClick={e => e.preventDefault()}>
              <img src="../../user-thumbnail.png" style={{ borderRadius: "100px", width: "34px" }} />
            </a>
          </Dropdown>
        </div>
      </Navbar>
    </div>
  );
}

export default HeaderMenu;
