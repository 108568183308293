import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import { Line, Circle } from 'rc-progress';
import './index.scss';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Axios from "axios";
import { message, Tag } from "antd";
import "antd/dist/antd.css";
import { useGetAllDepartmentQuery } from '../../services/departments';
import { useGetAllNationalityQuery } from '../../services/nationalities';

import { useGetAllProductsByIdQuery, useGetSupplierByIdQuery, useDeleteProductMutation, useCreateProductMutation, useUpdateProductMutation } from '../../services/products';

import { useDispatch, useSelector } from "react-redux";
import Skeletons from './Skeletons'
import moment from 'moment'
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "../Pagination";
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
const KEY = "ADD_PRODUCTS";
function Suppliers() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const { getId } = useParams();
  const dispatch = useDispatch()
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, } = useForm();
  const { register: register3, handleSubmit: handleSubmit3, formState: { errors: errors3 }, } = useForm();
  const [show, setShow] = useState(false);

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10
  const [getSearchQuery, searchQuery] = useState("")

  const singleSupplier = useGetSupplierByIdQuery({ id: getId, userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const supplierDetails = singleSupplier.data && singleSupplier?.data?.supplier;



  const supplierData = useGetAllProductsByIdQuery({ page: page, size: limit, id: getId, searchquery: getSearchQuery, userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const products = supplierData.data && supplierData?.data?.products;
  const pagination = supplierData.data && supplierData?.data?.pagination;

  const departmentList = useGetAllDepartmentQuery({ count: 1 }, { refetchOnMountOrArgChange: true })
  const departmentData = departmentList.data && departmentList.data;
  const nationalityList = useGetAllNationalityQuery({ count: 1 }, { refetchOnMountOrArgChange: true })
  const nationalityData = nationalityList.data && nationalityList.data;



  const [createNewaa, createresult] = useCreateProductMutation()
  const [deleteList, deleteresult] = useDeleteProductMutation()

  const [getEditShow, setEditShow] = useState(false);
  const [getNationality, setNationality] = useState("")
  const [getDepartment, setDepartment] = useState("")
  const [getProduct, setProduct] = useState("")
  const [getSalePrice, setSalePrice] = useState("")
  const [getBuyPrice, setBuyPrice] = useState("")
  const [getEditId, setEditId] = useState("")
  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(data.status)
    setNationality(data.nationality)
    setDepartment(data.department)
    setProduct(data.product)
    setSalePrice(data.sale_price)
    setBuyPrice(data.buy_price)
    setEditId(data.id)
  }

  const updateData = {
    id: getEditId,
    nationality: getNationality,
    department: getDepartment,
    product: getProduct,
    sale_price: getSalePrice,
    buy_price: getBuyPrice,
    userId: userId, userToken: userToken, admin: admin
  }

  const [editData, updateresult] = useUpdateProductMutation()




  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
    }
    if (createresult?.isSuccess) {
      setShow(false)
    }
  }, [updateresult, createresult]);


  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }




  return (
    <div className="Order-style">



      <Modal className="new_Modal" show={show} onHide={handleClose} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(createNewaa)}>
            <div className="row">
              <input type="hidden" id="supplier_id" defaultValue={getId} aria-invalid={errors.supplier_id ? "true" : "false"}
                {...register('supplier_id', { required: false })} />
              <input type="hidden" id="userId" defaultValue={userId} aria-invalid={errors.userId ? "true" : "false"}
                {...register('userId', { required: false })} />
              <input type="hidden" id="userToken" defaultValue={userToken} aria-invalid={errors.userToken ? "true" : "false"}
                {...register('userToken', { required: false })} />
              <input type="hidden" id="admin" defaultValue={admin} aria-invalid={errors.admin ? "true" : "false"}
                {...register('admin', { required: false })} />
              <div className="col-md-12">
                <div className="form-group">
                  <label>Departments</label>
                  <select className="form-control dlrdflInput" id="department" aria-invalid={errors.department ? "true" : "false"}
                    {...register('department', { required: true })}>
                    <option selected disabled value="">Select</option>
                    {departmentData && departmentData.departments.map((item, i) =>
                      <option value={`${item.name}`}>{item.name}</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Nationalities</label>
                  <select className="form-control dlrdflInput" id="nationality" aria-invalid={errors.nationality ? "true" : "false"}
                    {...register('nationality', { required: true })}>
                    <option selected disabled value="">Select</option>
                    {nationalityData && nationalityData.nationalities.map((item, i) =>
                      <option value={`${item.name}`}>{item.name}</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Product</label>
                  <input type="text" className="form-control dlrdflInput" id="product"
                    aria-invalid={errors.product ? "true" : "false"}
                    {...register('product', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Buy Price</label>
                  <input type="text" className="form-control dlrdflInput" id="buy_price"
                    aria-invalid={errors.buy_price ? "true" : "false"}
                    {...register('buy_price', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Sale Price</label>
                  <input type="text" className="form-control dlrdflInput" id="sale_price"
                    aria-invalid={errors.sale_price ? "true" : "false"}
                    {...register('sale_price', { required: true })} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  {createresult?.isLoading ? <Button variant="info" size="sm" className="hghtbtn" disabled>Creating</Button> : <Button variant="info" size="sm" className="hghtbtn" type="submit">Create</Button>}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>


      <Modal className="new_Modal" show={getEditShow} onHide={handleCloseEdit} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Departments</label>
                <select className="form-control dlrdflInput" onChange={(e) => setDepartment(e.target.value)}>
                  <option selected value={getDepartment}>{getDepartment}</option>
                  {departmentData && departmentData.departments.map((item, i) =>
                    <option value={`${item.name}`}>{item.name}</option>
                  )}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Nationalities</label>
                <select className="form-control dlrdflInput" onChange={(e) => setNationality(e.target.value)}>
                  <option selected disabled value={getNationality}>{getNationality}</option>
                  {nationalityData && nationalityData.nationalities.map((item, i) =>
                    <option value={`${item.name}`}>{item.name}</option>
                  )}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Product</label>
                <input type="text" className="form-control dlrdflInput" value={getProduct}
                  onChange={(e) => setProduct(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Buy Price</label>
                <input type="number" className="form-control dlrdflInput" value={getBuyPrice}
                  onChange={(e) => setBuyPrice(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Sale Price</label>
                <input type="number" className="form-control dlrdflInput" value={getSalePrice}
                  onChange={(e) => setSalePrice(e.target.value)} />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                {updateresult?.isLoading ? <Button variant="info" size="sm" className="hghtbtn" disabled>Updating</Button> : <Button variant="info" size="sm" className="hghtbtn" onClick={() => { editData(updateData) }}>Update</Button>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="Order-Top_navbar">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4>Supplier: &nbsp;<span style={{ color: '#a1a1a1' }}>{supplierDetails?.name}</span></h4>
                {(supplierData?.isFetching) && (!supplierData?.isLoading) &&
                  <div className="bouncing-loader" style={{ paddingLeft: '15px', paddingTop: '4px' }}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                }
              </div>
              <div className="dFlex center nowrap">
                <Button className="mr10 st_button" onClick={() => handleOpen(true)}>
                  Add Product
                </Button>
                <div className="table_search_card">
                  <input className="form-control table_search" placeholder="Search..." onChange={(e) => searchQuery(e.target.value)} />
                  <SearchIcon className="table_search_icon" />
                </div>

              </div>
            </div>

            <div className="Order-Pagination">
              <Pagination page={page} limit={limit} pagination={pagination} />
            </div>




            <div className="Order-Table_view">
              <div className="Order-Table_card">
                <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                  <div className="Order-Table_item heading fw700">Product</div>
                  <div className="Order-Table_item heading fw700">Dep.</div>
                  <div className="Order-Table_item heading fw700">Nationality</div>
                  <div className="Order-Table_item heading fw700">Sale Price</div>
                  <div className="Order-Table_item heading fw700">Buy Price</div>
                  <div className="Order-Table_item heading fw700">Date</div>
                  <div className="Order-Table_item heading sticky-column tar fw700">Manage</div>
                </div>
                {supplierData?.isLoading &&
                  <Skeletons />
                }
                {products?.map((item, i) =>
                  <div className="Order-Table_subcard">
                    <div className="Order-Table_item text">{item.product}</div>
                    <div className="Order-Table_item text">{item.department}</div>
                    <div className="Order-Table_item text">{item.nationality}</div>
                    <div className="Order-Table_item text">{item.sale_price}</div>
                    <div className="Order-Table_item text">{item.buy_price}</div>
                    <div className="Order-Table_item text">
                      {moment(item.createdAt).local().format("MMM Do YY, h:mm")}
                    </div>
                    <div className="Order-Table_item text sticky-column">
                      <div className="dFlex center nowrap jcr">


                        <a className='mr10' onClick={() => handleOpenEdit({
                          "status": true,
                          "nationality": item.nationality,
                          "department": item.department,
                          "product": item.product,
                          "sale_price": item.sale_price,
                          "buy_price": item.buy_price,
                          "id": item.id
                        })}>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                          >
                            <EditFilled style={{ fontSize: '18px' }} />
                          </IconButton>
                        </a>




                        {item.status === 0 ?
                          <small className="dFlex">
                            {deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id ?
                              <div className="bouncing-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              :
                              <a className='mr10' onClick={() => deleteList({ activate: 'activate', id: item.id, userId: userId, userToken: userToken, admin: admin })}>
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={open ? 'long-menu' : undefined}
                                  aria-expanded={open ? 'true' : undefined}
                                  aria-haspopup="true"
                                >
                                  <DoDisturbOnIcon style={{ fontSize: '22px', color: '#ea8087', }} />
                                </IconButton>
                              </a>
                            }
                          </small> :
                           <small className="dFlex">
                           {deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id ?
                             <div className="bouncing-loader">
                               <div></div>
                               <div></div>
                               <div></div>
                             </div>
                             :
                             <a className='mr10' onClick={() => deleteList({ deactivate: 'deactivate', id: item.id, userId: userId, userToken: userToken, admin: admin })}>
                               <IconButton
                                 aria-label="more"
                                 id="long-button"
                                 aria-controls={open ? 'long-menu' : undefined}
                                 aria-expanded={open ? 'true' : undefined}
                                 aria-haspopup="true"
                               >
                                 <CheckCircleIcon style={{ fontSize: '22px', color: '#7fc687', }} />
                               </IconButton>
                             </a>
                           }
                         </small>
                        }

                        {/* <Tag className="booksTag" color={item.status === 0 ? '#ea8087' : '#7fc687'} style={{margin: 0}}>
                          {item.status === 0 ? 'Deleted' : 'Active'}
                        </Tag> */}

                      </div>
                    </div>
                  </div>
                )}


              </div>
              {products?.length === 0 &&
                <h1 className="Order-no_data_found">Not Data Found!</h1>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Suppliers;
