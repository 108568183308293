import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import { Line, Circle } from 'rc-progress';
import './index.scss';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from '@material-ui/icons/Edit';
import Axios from "axios";
import ReactToPrint from "react-to-print";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { message, Tag } from "antd";
import moment from 'moment'
import PrintIcon from '@mui/icons-material/Print';
function OrderPrint(props) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);

  let printRef = null;

  const checkBalance = Number(props.GTPlusFees) - Number(props.orders?.paid)
  const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
  return (
    <div>
      <ReactToPrint
        trigger={() =>
          <Button variant="warning" size="sm">
            <small className="dFlex"><PrintIcon style={{ fontSize: '18px', color: '#ffff' }} /></small>
          </Button>
        }
        content={() => printRef}
      />

      <div className="container" style={{ display: "none" }}>
        <div className="row cardList__margin printCard Order-style" ref={el => (printRef = el)}>
          <div className="col-md-12">
            <div className="storeCardH BrdrRound">

              <div style={{ position: 'absolute', right: 0, left: 0, display: 'flex', alignItems: 'center',justifyContent: 'center', opacity: 0.02 }}>
                <img style={{ width: '100%', backgroundPosition: 'center', backgroundSize: 'cover' }} src="/sevenFlight.png" />
              </div>

              <div className="storeCardB" style={{ padding: "0px" }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px', justifyContent: 'flex-end' }}>
                      <span><strong>Invoice Number:</strong></span>&nbsp;&nbsp;<span>#{props.orders?.id}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px', justifyContent: 'flex-end' }}>
                      <span><strong>Date:</strong></span>&nbsp;&nbsp;<span>
                        {moment(props.orders?.createdAt).local().format("MMM Do YY")}
                      </span>
                    </div>
                  </div>
                </div>

                {props.orders?.state === 'paid' &&
                  <div style={{ fontSize: "34px", width: "100%", textAlign: "right", color: "#c9c9c9", paddingBottom: "10px" }}>PAID</div>
                }
                {props.orders?.state === 'pending' &&
                  <div style={{ fontSize: "34px", width: "100%", textAlign: "right", color: "#c9c9c9", paddingBottom: "10px" }}>UNPAID</div>
                }
                {props.orders?.state === '' &&
                  <div style={{ fontSize: "34px", width: "100%", textAlign: "right", color: "#c9c9c9", paddingBottom: "10px" }}>UNPAID</div>
                }


                <table className="table" style={{ border: "none" }}>
                  <tbody style={{ border: "none" }}>
                    <tr style={{ border: "none" }}>
                      <td style={{ textAlign: "left", width: "60%", borderTop: 'none', padding: '0px 20px 20px 0px' }}>
                        <div style={{ lineHeight: 1.7 }}>
                          <img src='/seven.png' style={{ width: '250px' }} />
                          <div style={{ marginTop: "10px", fontSize: 24 }}>Seven Tourism</div>
                          <div>Office #6, Main Market, Jama Masjid Road Joya.</div>
                          <div>Amroha, UP, India 244222</div>
                          <div>info@seventourism.com, asjadseventourism@gmail.com</div>
                          <div>+91 9990828161, +91 7500165786</div>
                          <div>IGST: 09CUGPA8627M1Z6</div>
                        </div>
                      </td>
                      <td style={{ borderTop: 'none', width: "40%", padding: '0px 0px 20px 0px' }}>
                        <br /><br />
                        <div style={{ background: '#212945', padding: '7px 10px 7px 10px', color: '#fcfcfc' }}>To</div>
                        <div>
                          <div style={{ marginTop: "7px", display: 'flex', alignItems: 'center', paddingBottom: '5px' }}>
                            <span style={{ fontSize: '18px' }}>{props.orders?.name}</span>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px' }}>
                            <div><strong>Address:&nbsp;&nbsp;</strong>
                              {props.orders?.address}</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px' }}>
                            <span><strong>E:&nbsp;&nbsp;</strong></span>
                            <span>{props.orders?.email}</span>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px' }}>
                            <span><strong>M:&nbsp;&nbsp;</strong></span>
                            <span>{props.orders?.mobile}</span>
                          </div>
                          {props.orders?.number &&
                            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px' }}>
                              <span><strong>GST:&nbsp;&nbsp;</strong></span>
                              <span>{props.orders?.number}</span>
                            </div>
                          }
                          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px' }}>
                            <span><strong>Currency:&nbsp;&nbsp;</strong></span>
                            <span>INR</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                {props.orders?.package === 0 &&
                  <div className="Order-Table_card">
                    <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                      <div className="Order-Table_item printBorder" style={{ fontWeight: 700, border: '1px solid #3a3a3a54' }}>Item</div>
                      <div className="Order-Table_item printBorder" style={{ fontWeight: 700, border: '1px solid #3a3a3a54', borderLeft: 'none' }}>Qty.</div>
                      <div className="Order-Table_item printBorder" style={{ fontWeight: 700, border: '1px solid #3a3a3a54', borderLeft: 'none' }}>Price</div>
                      <div className="Order-Table_item printBorder" style={{ fontWeight: 700, border: '1px solid #3a3a3a54', borderLeft: 'none', textAlign: 'right' }}>Total</div>
                    </div>

                    {props.orderItems?.map((item, i) =>
                      <div className="Order-Table_subcard">
                        <div className="Order-Table_item printBorder" style={{ border: '1px solid #3a3a3a54', borderTop: 'none' }}>
                          <div>{item.item} </div>
                          <div><small>{item.description}</small></div>
                        </div>
                        <div className="Order-Table_item printBorder" style={{ border: '1px solid #3a3a3a54', borderTop: 'none', borderLeft: 'none', }}>{item.quantity}</div>
                        <div className="Order-Table_item printBorder" style={{ border: '1px solid #3a3a3a54', borderTop: 'none', borderLeft: 'none', }}>
                          <div>
                            <strong> {priceSplitter(Number(item.price) + Number(item.addon))}</strong>
                          </div>
                        </div>
                        <div className="Order-Table_item printBorder" style={{ border: '1px solid #3a3a3a54', borderTop: 'none', borderLeft: 'none', textAlign: 'right' }}>
                          <div>
                            <strong>{priceSplitter(Number(item.total) + Number(props.totalOrderItemsDiscount))}</strong>
                          </div>
                          {item.tax === 'GST' &&
                            <div><small><Tag style={{ margin: 0 }} color={'#2db7f5'}> inclusive GST(18%)</Tag></small></div>
                          }
                          {item.tax === 'VAT' &&
                            <div><small><Tag style={{ margin: 0 }} color={'#2db7f5'}>inclusive VAT(5%)</Tag></small></div>
                          }
                        </div>
                      </div>
                    )}
                  </div>
                }

                {props.orders?.package === 1 &&
                  <div className="Order-Table_card">
                    <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                      <div className="Order-Table_item" style={{ fontWeight: 700 }}>Item</div>
                      <div className="Order-Table_item" style={{ fontWeight: 700 }}>Qty.</div>
                      <div className="Order-Table_item" style={{ fontWeight: 700 }}>Price</div>
                      <div className="Order-Table_item" style={{ fontWeight: 700 }}>Discount</div>
                      <div className="Order-Table_item" style={{ fontWeight: 700, textAlign: 'right' }}>Total</div>
                    </div>

                    <div className="Order-Table_subcard">
                      <div className="Order-Table_item">
                        <div>{props.orders?.package_title} </div>
                        <div><small>{props.orders?.package_description}</small></div>
                      </div>
                      <div className="Order-Table_item">{'1'}</div>
                      <div className="Order-Table_item">
                        <div>
                          <strong> {priceSplitter(Number(props.totalOrderItems))}</strong>
                        </div>
                      </div>
                      <div className="Order-Table_item">{props.totalOrderItemsDiscount}</div>
                      <div className="Order-Table_item" style={{ textAlign: 'right' }}>
                        <div>
                          <strong>{priceSplitter(props.totalOrderItems)}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <table className="table" style={{ border: "none" }}>
                  <tbody style={{ border: "none" }}>
                    <tr style={{ border: "none" }}>
                      <td style={{ textAlign: "left", width: "60%", border: "none", fontSize: '11px', padding: '20px 20px 0px 0px', borderRight: '1px solid #eee', whiteSpace: 'normal' }}>
                        <div><strong>Terms & Conditions</strong></div>
                        <div><strong>Note*:</strong> Cancellation Charges of 20% of the Refund amount applicable on sales.</div>
                        <ul style={{ padding: '14px' }}>
                          <li>Visa Approval is up to immigration solely.</li>
                          <li>Seven Tourism will apply tourist Visa in the guidance of DTCM Rules. In case of any rejection due to any reason we will not be responsible for Application charge.</li>
                          <li>If fight is booked already and visa approval is delayed by immigration then we will not be liable for fight cancellation.</li>
                          <li>If person does not leave UAE within required time as per visa then all  nes will be paid by Applicant / Guarantor of Applicant.</li>
                        </ul>
                      </td>
                      <td style={{ textAlign: "right", border: "none", width: "40%", padding: '20px 15px 0px 15px', borderRight: '1px solid #eee' }}>
                        <div style={{ marginBottom: "5px", display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #eee", paddingBottom: "7px" }}><strong>SubTotal</strong> &nbsp; <span>{priceSplitter(Number(props.totalOrderItems + Number(props.totalOrderItemsDiscount)))}</span></div>

                        {props.totalOrderItemsDiscount > 0 &&
                          <div style={{ marginBottom: "5px", display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #eee", paddingBottom: "7px" }}><strong>Discount</strong> &nbsp; <span>-{priceSplitter(Number(props.totalOrderItemsDiscount))}</span></div>
                        }


                        {props.orders?.fees > 0 &&
                          <div style={{ marginBottom: "5px", display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #eee", paddingBottom: "7px" }}><strong>Fee</strong> &nbsp; <span>{priceSplitter(Number(props.orders?.fees))}</span></div>
                        }
                        {props.orders?.discount > 0 &&
                          <div style={{ marginBottom: "5px", display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #eee", paddingBottom: "7px" }}><strong>{'>'}&nbsp;Discount</strong> &nbsp; <span>-{Number(props.orders?.discount)}</span></div>
                        }
                        {props.orders?.tax === 'GST' &&
                          <div style={{ marginBottom: "5px", display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #eee", paddingBottom: "7px" }}><strong>{'>'}&nbsp;GST Charged(18%)</strong> &nbsp;
                            <span>{props.GTFeesTaxOnly}</span>
                          </div>
                        }
                        {props.orders?.tax === 'VAT' &&
                          <div style={{ marginBottom: "5px", display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #eee", paddingBottom: "7px" }}><strong>{'>'}&nbsp;VAT Charged (5%)</strong> &nbsp;
                            <span>{props.GTFeesTaxOnly}</span>
                          </div>
                        }
                        <div style={{ marginBottom: "5px", display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #eee", paddingBottom: "7px", fontSize: '20px' }}><strong>Grand Total</strong> &nbsp; <span> {priceSplitter(props.GTPlusFees)}</span></div>

                        <div style={{ marginBottom: "5px", display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #eee", paddingBottom: "7px", fontSize: '18px' }}><strong>Paid</strong> &nbsp; <span> {priceSplitter(props.orders?.paid)}</span></div>
                        {checkBalance > 0 &&
                          <div style={{ marginBottom: "5px", display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #eee", paddingBottom: "7px", fontSize: '18px' }}><strong>Balance</strong> &nbsp; <span> {priceSplitter(Number(props.GTPlusFees) - Number(props.orders?.paid))}</span></div>
                        }

                      </td>
                    </tr>
                  </tbody>
                </table>


                <table className="table" style={{ border: "none" }}>
                  <tbody style={{ border: "none" }}>
                    <tr style={{ border: "none" }}>
                      <td style={{ textAlign: "left", border: "none", width: "65%", paddingTop: "50px", fontSize: "13px" }}>

                      </td>
                      <td style={{ textAlign: "right", border: "none", width: "35%", paddingTop: "50px" }}>
                        <div>Authorized Signature</div>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderPrint;
