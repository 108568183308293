import React, { Component } from 'react';
import './index.scss';

function NotFound() {
  return (
    <div className="Page-not_foung">
    <div className="row newStore ">
      <div className="col-md-12"> 
        <div>
          <h4 className="ntFoundh">404 Error!</h4>
      </div>     
    </div>
    </div>
    </div>
  );
}

export default NotFound;
