import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import OrderPrint from './print';
import './index.scss';
import { useDispatch, useSelector } from "react-redux";
import { useGetOrdersByIdQuery, useCreateOrderItemMutation, useDeleteOrderItemMutation, useUpdateOrderItemMutation, useUpdateOrdersMutation } from '../../services/orders';
import { useGetProductByNationalityQuery } from '../../services/products';
import moment from 'moment'
import Select from 'react-select';
import Skeletons from './Skeletons'
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import { message, Tag } from "antd";
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import IconButton from '@mui/material/IconButton';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
function ManageOrder() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const { orderId } = useParams();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const ordersData = useGetOrdersByIdQuery({
    orderId: orderId,
    userId: userId,
    userToken: userToken,
    admin: admin
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const orders = ordersData?.data && ordersData?.data?.order;


  const [selectedOption, setSelectedOption] = useState(null);
  const productsData = useGetProductByNationalityQuery({
    nationality: orders?.nationality,
    userId: userId,
    userToken: userToken,
    admin: admin
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const products = productsData?.data && productsData?.data?.products;

  const filtrProduct = products?.filter(item => (item.nationality === selectedOption?.nationality) && (item.id === selectedOption?.id));
  const productPrice = filtrProduct && filtrProduct[0]

  function renderList() {
    return (products?.map(data => ({ label: data.product, value: data.id, nationality: data.nationality, id: data.id })))
  }


  const [getItem, setItem] = useState('');
  const [getDescription, setDescription] = useState('');
  const [getTax, setTax] = useState('');
  const [getDiscount, setDiscount] = useState('');
  const [getAddon, setAddon] = useState('');
  const [getQuantity, setQuantity] = useState('');
  const createOrderItemData = {
    order_id: orders?.id,
    product_id: productPrice?.id,
    department: productPrice?.department,
    quantity: getQuantity ? getQuantity : 1,
    addon: getAddon ? getAddon : 0,
    price: productPrice?.sale_price ? productPrice?.sale_price : 0,
    item: getItem,
    description: getDescription,
    tax: getTax,
    discount: getDiscount ? getDiscount : 0,
    userId: userId, userToken: userToken, admin: admin
  }
  const [createOrderItem, createresult] = useCreateOrderItemMutation()
  const [getNewOrderItemModal, setNewOrderItemModal] = useState(false);
  function closeNewOrderItemModal() {
    setNewOrderItemModal(false)
  }
  function createNewOrderItem() {
    setNewOrderItemModal(true)
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      setNewOrderItemModal(false)
      setQuantity(null)
      setAddon(null)
      setDiscount(null)
      setTax(null)
      setDescription(null)
      setItem(null)
      setSelectedOption(null)
    }
  }, [createresult]);




  const totalOrderItems = orders?.order_items?.reduce((total, currentValue) => total = total + currentValue.total, 0);
  const totalOrderItemsDiscount = orders?.order_items?.reduce((total, currentValue) => total = total + currentValue.discount, 0);


  const [deleteCampaign, deleteresult] = useDeleteOrderItemMutation()

  const [getEditShow, setEditShow] = useState(false);
  const [getOrderItem, setOrderItem] = useState("")
  const [getOrderItemDescription, setOrderItemDescription] = useState("")
  const [getOrderItemQuantity, setOrderItemQuantity] = useState("")
  const [getOrderItemAddon, setOrderItemAddon] = useState("")
  const [getOrderItemDiscount, setOrderItemDiscount] = useState("")
  const [getOrderItemPrice, setOrderItemPrice] = useState("")
  const [getOrderItemTax, setOrderItemTax] = useState("")
  const [getOrderItemId, setOrderItemId] = useState("")
  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(data.status)
    setOrderItem(data.orderItem)
    setOrderItemDescription(data.orderItemDescription)
    setOrderItemQuantity(data.orderItemQuantity)
    setOrderItemAddon(data.orderItemAddon)
    setOrderItemDiscount(data.orderItemDiscount)
    setOrderItemPrice(data.orderItemPrice)
    setOrderItemTax(data.orderItemTax)
    setOrderItemId(data.orderItemId)
  }

  const updateData = {
    id: getOrderItemId,
    quantity: getOrderItemQuantity ? getOrderItemQuantity : 1,
    addon: getOrderItemAddon ? getOrderItemAddon : 0,
    price: getOrderItemPrice ? getOrderItemPrice : 0,
    item: getOrderItem,
    description: getOrderItemDescription,
    tax: getOrderItemTax,
    discount: getOrderItemDiscount ? getOrderItemDiscount : 0,
    userId: userId, userToken: userToken, admin: admin
  }






  const [getEditShow2, setEditShow2] = useState(false);
  const [getOrderDiscount, setOrderDiscount] = useState("")
  const [getOrderFees, setOrderFees] = useState("")
  const [getOrderTax, setOrderTax] = useState("")
  function handleCloseEdit2() {
    setEditShow2(false)
  }
  function handleOpenEdit2(data) {
    setEditShow2(data.status)
    setOrderDiscount(data.orderDiscount)
    setOrderFees(data.orderFees)
    setOrderTax(data.orderTax)
  }

  const updateData2 = {
    id: orders?.id,
    discount: getOrderDiscount ? getOrderDiscount : 0,
    fees: getOrderFees ? getOrderFees : 0,
    tax: getOrderTax,
    fees_update: 'fees_update',
    userId: userId, userToken: userToken, admin: admin
  }
  const [updateOrder, updateOrderResult] = useUpdateOrdersMutation()
  useEffect(() => {
    if (updateOrderResult?.isSuccess) {
      setEditShow2(false)
      setPayModal(false)
      setPackageModal(false)
      setNoteModal(false)
    }
  }, [updateOrderResult]);




  // pay
  const [getAmmountPay, setAmmountPay] = useState('');
  const [getPayBuy, setPayBuy] = useState('');
  const [getPayModal, setPayModal] = useState(false);
  const GTFees = Number(orders?.fees) - Number(orders?.discount)
  let GTFeesTax;
  if (orders?.tax === 'GST') {
    GTFeesTax = Number(GTFees) * Number(0.18) + Number(GTFees)
  }
  else if (orders?.tax === 'VAT') {
    GTFeesTax = Number(GTFees) * Number(0.05) + Number(GTFees)
  } else {
    GTFeesTax = GTFees
  }

  let GTFeesTaxOnly;
  if (orders?.tax === 'GST') {
    GTFeesTaxOnly = Number(GTFees) * Number(0.18)
  }
  else if (orders?.tax === 'VAT') {
    GTFeesTaxOnly = Number(GTFees) * Number(0.05)
  } else {
    GTFeesTaxOnly = GTFees
  }


  const GTPlusFees = Number(totalOrderItems) + Number(GTFeesTax)
  let payState;
  if (Number(GTPlusFees) === (Number(orders?.paid) + Number(getAmmountPay))) {
    payState = 'paid'
  }
  else if ((Number(orders?.paid) + Number(getAmmountPay)) > Number(GTPlusFees)) {
    payState = 'paid'
  } else {
    payState = 'pending'
  }
  const payData = {
    id: orders?.id,
    paid: getAmmountPay ? getAmmountPay : 0,
    paid_by: getPayBuy,
    total: GTPlusFees,
    state: payState,
    paying: 'paying',
    userId: userId, userToken: userToken, admin: admin
  }

  function payModal() {
    setPayModal(true)
  }
  function closePayModal() {
    setPayModal(false)
  }



  const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));





  // package
  const [getPackageModal, setPackageModal] = useState(false);
  const [getPackageTitle, setPackageTitle] = useState('');
  const [getPackageDescription, setPackageDescription] = useState('');
  const [getPackage, setPackage] = useState(false);

  const packageData = {
    id: orders?.id,
    package_title: getPackageTitle,
    package_description: getPackageDescription,
    package: getPackage,
    userId: userId, userToken: userToken, admin: admin
  }

  function createAPackage(data) {
    setPackageModal(data.status)
    setPackageTitle(data.packageTitle)
    setPackageDescription(data.packageDescription)
    setPackage(data.package)
  }
  function closePackageModal() {
    setPackageModal(false)
  }


  // Note
  const [getNoteModal, setNoteModal] = useState(false);
  const [getNote, setNote] = useState('');

  const noteData = {
    id: orders?.id,
    order_note: getNote,
    note: 'note',
    userId: userId, userToken: userToken, admin: admin
  }
  function AddNote(data) {
    setNoteModal(data.status)
    setNote(data.order_note)
  }
  function closeNoteModal() {
    setNoteModal(false)
  }


  const [updatePost, updateresult] = useUpdateOrderItemMutation()
  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
    }
  }, [updateresult]);


  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [symbolsArrQty] = useState(["e", "E", "+", "-", ".", "1"]);

  function checkForTotal() {
    const GTFees = Number(orders?.fees) - Number(orders?.discount)
    let GTFeesTax;
    if (orders?.tax === 'GST') {
      GTFeesTax = Number(GTFees) * Number(0.18) + Number(GTFees)
    }
    else if (orders?.tax === 'VAT') {
      GTFeesTax = Number(GTFees) * Number(0.05) + Number(GTFees)
    } else {
      GTFeesTax = GTFees
    }
    let GTFeesTaxOnly;
    if (orders?.tax === 'GST') {
      GTFeesTaxOnly = Number(GTFees) * Number(0.18)
    }
    else if (orders?.tax === 'VAT') {
      GTFeesTaxOnly = Number(GTFees) * Number(0.05)
    } else {
      GTFeesTaxOnly = GTFees
    }

    const GTPlusFees = Number(totalOrderItems) + Number(GTFeesTax)
    return (
      <div className="balance_Box">
        <div className="balance_Text"><span className="mr10"><strong>Total</strong></span><span>{priceSplitter(totalOrderItems)}</span></div>
        <div style={{ borderLeft: '2px solid red', paddingLeft: '10px' }}>
          <div className="balance_Text"><span className="mr10"><strong>Fees</strong></span><span>{priceSplitter(orders?.fees)}</span></div>
          <div className="balance_Text"><span className="mr10">{'>'} &nbsp;<strong>Discount</strong></span><span>{priceSplitter(orders?.discount)}</span></div>

          <div className="balance_Text"><span className="mr10">{'>'} &nbsp;<strong>Tax</strong></span>
            {orders?.tax === 'GST' &&
              <span><Tag style={{ margin: 0 }} color={'#2db7f5'}>GST Charged (18%)</Tag>&nbsp;{GTFeesTaxOnly}</span>
            }
            {orders?.tax === 'VAT' &&
              <span><Tag style={{ margin: 0 }} color={'#2db7f5'}>VAT Charged (5%)</Tag>&nbsp;{GTFeesTaxOnly}</span>
            }
          </div>
        </div>
        <div className="balance_Text"><span className="mr10"><strong>Grand Total</strong></span><span style={{ fontSize: '20px' }}>
          <strong>{priceSplitter(Math.round(GTPlusFees))}</strong></span></div>
        <div className="balance_Text"><span className="mr10"><strong>Paid Amount</strong></span><span>{priceSplitter(orders?.paid)}</span></div>
        <div className="balance_Text"><span className="mr10"><strong>Balance</strong></span><span>{priceSplitter(Number(GTPlusFees) - Number(orders?.paid))}</span></div>
        <div className="balance_Text"><span className="mr10"><strong>Currency</strong></span><span>INR</span></div>
      </div>
    )
  }


  return (
    <div className="Order-style">

      <Modal className="Order-New_modal" show={getNewOrderItemModal} onHide={closeNewOrderItemModal} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-8">
              <div className="form-group">
                <label>Select Product</label>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={renderList()}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Price</label>
                <input type="text" disabled className="form-control" value={Number(productPrice?.sale_price) ? Number(productPrice?.sale_price) + Number(getAddon) : Number(getAddon)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Addon Price</label>
                <input type="number" min="0" onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()} className="form-control input_style" value={getAddon}
                  onChange={(e) => setAddon(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Qty.</label>
                <input type="number" min="1" onKeyDown={e => symbolsArrQty.includes(e.key) && e.preventDefault()} className="form-control input_style" value={getQuantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Item</label>
                <input type="text" className="form-control input_style"
                  value={getItem}
                  onChange={(e) => setItem(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Description</label>
                <input type="text" className="form-control input_style"
                  value={getDescription}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Tax</label>
                <select className="form-control input_style"
                  onChange={(e) => setTax(e.target.value)}
                >
                  <option value={''} selected disabled>None</option>
                  <option value={'GST'}>GST</option>
                  <option value={'VAT'}>VAT</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Discount</label>
                <input type="number" min="0" onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()} className="form-control input_style" value={getDiscount}
                  onChange={(e) => setDiscount(e.target.value)}
                />
              </div>
            </div>


            <div className="col-md-4">
              <div className="form-group">
                {createresult?.isLoading ?
                  <Button variant="info" size="sm" className="button_style" disabled>Creating</Button> :
                  <Button variant="info" size="sm" className="button_style"
                    onClick={() => { (selectedOption) && (getItem) && createOrderItem(createOrderItemData) }}>Create</Button>}

              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="Order-New_modal" show={getEditShow} onHide={handleCloseEdit} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <section><span className="loader-72"> </span></section>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Addon Price</label>
                <input type="number" min="0" onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()} className="form-control input_style" value={getOrderItemAddon}
                  onChange={(e) => setOrderItemAddon(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Qty.</label>
                <input type="number" min="1" onKeyDown={e => symbolsArrQty.includes(e.key) && e.preventDefault()} className="form-control input_style" value={getOrderItemQuantity}
                  onChange={(e) => setOrderItemQuantity(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Item</label>
                <input type="text" className="form-control input_style"
                  value={getOrderItem}
                  onChange={(e) => setOrderItem(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Description</label>
                <input type="text" className="form-control input_style"
                  value={getOrderItemDescription}
                  onChange={(e) => setOrderItemDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Tax</label>
                <select className="form-control input_style"
                  onChange={(e) => setOrderItemTax(e.target.value)}
                >
                  {/* <option value={getOrderItemTax} selected>{getOrderItemTax}</option> */}
                  {getOrderItemTax === 'GST' &&
                    <>
                      <option value={'GST'}>GST</option>
                      <option value={'VAT'}>VAT</option>
                      <option value={''}>None</option>
                    </>
                  }
                  {getOrderItemTax === 'VAT' &&
                    <>
                      <option value={'VAT'}>VAT</option>
                      <option value={'GST'}>GST</option>
                      <option value={''}>None</option>
                    </>
                  }
                  {(getOrderItemTax === null) &&
                    <>
                      <option value={''}>None</option>
                      <option value={'VAT'}>VAT</option>
                      <option value={'GST'}>GST</option>
                    </>
                  }
                  {(getOrderItemTax === '') &&
                    <>
                      <option value={''}>None</option>
                      <option value={'VAT'}>VAT</option>
                      <option value={'GST'}>GST</option>
                    </>
                  }
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Discount</label>
                <input type="number" min="0" onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()} className="form-control input_style" value={getOrderItemDiscount}
                  onChange={(e) => setOrderItemDiscount(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                {updateresult?.isLoading ? <Button variant="info" size="sm" className="button_style" disabled>Updating</Button> : <Button variant="info" size="sm" className="button_style" onClick={() => { (getOrderItem) && updatePost(updateData) }}>Update</Button>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="Order-New_modal" show={getEditShow2} onHide={handleCloseEdit2} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <section><span className="loader-72"> </span></section>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Discount</label>
                <input type="number" min="0" onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()} className="form-control input_style" value={getOrderDiscount}
                  onChange={(e) => setOrderDiscount(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Fees</label>
                <input type="number" min="0" onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()} className="form-control input_style" value={getOrderFees}
                  onChange={(e) => setOrderFees(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Tax</label>
                <select className="form-control input_style"
                  onChange={(e) => setOrderTax(e.target.value)}
                >
                  {/* <option value={getOrderItemTax} selected>{getOrderItemTax}</option> */}
                  {orders?.tax === 'GST' &&
                    <>
                      <option value={'GST'}>GST</option>
                      <option value={'VAT'}>VAT</option>
                      <option value={''}>None</option>
                    </>
                  }
                  {orders?.tax === 'VAT' &&
                    <>
                      <option value={'VAT'}>VAT</option>
                      <option value={'GST'}>GST</option>
                      <option value={''}>None</option>
                    </>
                  }
                  {(orders?.tax === null) &&
                    <>
                      <option value={''}>None</option>
                      <option value={'VAT'}>VAT</option>
                      <option value={'GST'}>GST</option>
                    </>
                  }
                  {(orders?.tax === '') &&
                    <>
                      <option value={''}>None</option>
                      <option value={'VAT'}>VAT</option>
                      <option value={'GST'}>GST</option>
                    </>
                  }
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                {updateOrderResult?.isLoading ? <Button variant="info" size="sm" className="button_style" disabled>Updating</Button> : <Button variant="info" size="sm" className="button_style" onClick={() => { updateOrder(updateData2) }}>Update</Button>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal className="Order-New_modal" show={getPayModal} onHide={closePayModal} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <section><span className="loader-72"> </span></section>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Amount</label>
                <input type="number" required min="0" onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                  className="form-control input_style" value={getAmmountPay} onChange={(e) => setAmmountPay(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Pay by</label>
                <select className="form-control input_style" required onChange={(e) => setPayBuy(e.target.value)}>
                  <option value={''} selected disabled>Select</option>
                  <option value={'cash'}>Cash</option>
                  <option value={'card'}>Card</option>
                  <option value={'bank-transfer'}>Bank Transfer</option>
                  <option value={'paytm'}>Paytm</option>
                  <option value={'google-pay'}>Google Pay</option>
                  <option value={'phone-pay'}>Phone Pay</option>
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                {updateOrderResult?.isLoading ? <Button variant="info" size="sm" className="button_style" disabled>Updating</Button> : <Button variant="info" size="sm" className="button_style"
                  onClick={() => { (getAmmountPay) && (getPayBuy) && updateOrder(payData) }}>Update</Button>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal className="Order-New_modal" show={getPackageModal} onHide={closePackageModal} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <section><span className="loader-72"> </span></section>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Title</label>
                <input type="text" className="form-control input_style"
                  value={getPackageTitle}
                  onChange={(e) => setPackageTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Description</label>
                <input type="text" className="form-control input_style"
                  value={getPackageDescription}
                  onChange={(e) => setPackageDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Status</label>
                <select className="form-control input_style" required onChange={(e) => setPackage(e.target.value)}>
                  {getPackage &&
                    <>
                      <option value={1} selected disabled>True</option>
                      <option value={0}>Remove package</option>
                    </>
                  }
                  {!getPackage &&
                    <>
                      <option value={0} selected disabled>False</option>
                      <option value={1}>Crate a package</option>
                    </>
                  }
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                {updateOrderResult?.isLoading ? <Button variant="info" size="sm" className="button_style" disabled>Updating</Button> : <Button variant="info" size="sm" className="button_style"
                  onClick={() => { updateOrder(packageData) }}>Update</Button>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>



      <Modal className="Order-New_modal" show={getNoteModal} onHide={closeNoteModal} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <section><span className="loader-72"> </span></section>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Comment</label>
                <textarea type="text" className="form-control input_style"
                  onChange={(e) => setNote(e.target.value)}
                >{orders?.order_note}</textarea>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                {updateOrderResult?.isLoading ? <Button variant="info" size="sm" className="button_style" disabled>Updating</Button> : <Button variant="info" size="sm" className="button_style"
                  onClick={() => { updateOrder(noteData) }}>Update</Button>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>



      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="orderItem-Card">
              {(ordersData?.isFetching) && (!ordersData?.isLoading) &&
                <div className="bouncing-loader" style={{ position: 'fixed', paddingLeft: '20px', paddingTop: '10px' }}>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              }
              <div className="postTopCard">
                <div>
                  <div className="balance_Text">
                    <span><strong>Booking ID:</strong>&nbsp;&nbsp;#{orders?.id}</span>
                  </div>
                  <div className="balance_Text">
                    <span><strong>Created By:</strong>&nbsp;&nbsp;{ordersData?.isLoading ?  <Skeleton variant="rect" width={150} height={10} /> : orders?.user?.email}</span>
                   
                  </div>
                  <div className="cDetals">
                    <span><strong>Invoice To:</strong></span>
                  </div>
                  <div className="balance_Text">
                    <span><strong>Name/Company:</strong>&nbsp;&nbsp;{ordersData?.isLoading ?  <Skeleton variant="rect" width={150} height={10} /> : orders?.name}</span>
                  </div>
                  <div className="balance_Text">
                    <span><strong>Nationality:</strong>&nbsp;&nbsp;{ordersData?.isLoading ?  <Skeleton variant="rect" width={150} height={10} /> : orders?.nationality}</span>
                  </div>
                  <div className="balance_Text">
                    <span><strong>Mobile:</strong>&nbsp;&nbsp;{ordersData?.isLoading ?  <Skeleton variant="rect" width={150} height={10} /> : orders?.mobile}</span>
                  </div>
                  <div className="balance_Text">
                    <span><strong>Email:</strong>&nbsp;&nbsp;{ordersData?.isLoading ?  <Skeleton variant="rect" width={150} height={10} /> : orders?.email}</span>
                  </div>
                  <div className="balance_Text">
                    <span><strong>Addresss:</strong>&nbsp;&nbsp;{ordersData?.isLoading ?  <Skeleton variant="rect" width={150} height={10} /> : orders?.address}</span>
                  </div>
                  {orders?.number &&
                    <div className="balance_Text">
                      <span><strong>GST:</strong>&nbsp;&nbsp;{orders?.number}</span>
                    </div>
                  }
                  <div>
                    <div><strong>Date:&nbsp;&nbsp;{moment(orders?.createdAt).local().format("MMM Do YY, h:mm")}</strong></div>
                  </div>
                </div>
                <div>

                  <div className="dFlex" style={{ justifyContent: 'space-between' }}>
                    <div>&nbsp;</div>
                    <Button className="dFlex center nowrap btn-height" variant="warning" size="sm"
                      onClick={() => handleOpenEdit2({
                        status: true,
                        orderDiscount: orders?.discount,
                        orderFees: orders?.fees,
                        orderTax: orders?.tax
                      })}><small className="dFlex"><EditFilled style={{ fontSize: '15px' }} /></small></Button>
                  </div>
                  {checkForTotal()}
                  {orders?.state === 'paid' &&
                    <div style={{ fontSize: "28px", width: "100%", textAlign: "right", color: "#c9c9c9" }}>CLOSED</div>
                  }
                  {orders?.state === 'pending' &&
                    <div style={{ fontSize: "28px", width: "100%", textAlign: "right", color: "#c9c9c9" }}>PENDING</div>
                  }
                  {orders?.state === '' &&
                    <div style={{ fontSize: "28px", width: "100%", textAlign: "right", color: "#c9c9c9" }}>PENDING</div>
                  }
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="Order-Top_navbar">
                    <div>
                      {(orders?.order_items?.length == 50) || (orders?.order_items?.length < 50) &&
                        <Button className="mr10" variant="primary" size="sm" onClick={() => createNewOrderItem(true)}>
                          <small className="dFlex">Add Item</small>
                        </Button>
                      }
                      <Button className="mr10" variant="primary" size="sm" style={{ background: '#222945', border: '1px solid #222945' }}
                        onClick={() => createAPackage({
                          status: true,
                          packageTitle: orders?.package_title,
                          packageDescription: orders?.package_description,
                          package: orders?.package,
                        })}
                      >
                        <small className="dFlex">{orders?.package === 1 && 'Edit package'}{orders?.package === 0 && 'Create a package'}</small>
                      </Button>
                    </div>
                    <div className="dFlex center nowrap">
                      <Button className="mr10" variant="info" size="sm"
                        onClick={() => AddNote({
                          status: true,
                          orderNote: orders?.order_note,
                        })}
                      >
                        <small className="dFlex">{orders?.order_note ? 'Update comment' : 'Add comment'}</small>
                      </Button>
                      <Button className="mr10" variant="primary" size="sm" onClick={() => payModal(true)}>
                        <small className="dFlex">Pay</small>
                      </Button>

                      <OrderPrint orders={orders} totalOrderItems={totalOrderItems} GTPlusFees={GTPlusFees} orderItems={orders?.order_items} GTFeesTax={GTFeesTax} totalOrderItemsDiscount={totalOrderItemsDiscount} GTFeesTaxOnly={GTFeesTaxOnly} />
                    </div>
                  </div>

                  {orders?.package === 1 &&
                    <div className="Order-Table_view">
                      <div className="Order-Table_card">
                        <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                          <div className="Order-Table_item" style={{ fontWeight: 700 }}>Package Details</div>
                        </div>
                        <div className="Order-Table_subcard">
                          <div className="Order-Table_item">
                            <div>{orders?.package_title}</div>
                            <div>{orders?.package_description}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }


                  <div className="Order-Table_view">
                    <div className="Order-Table_card">
                      <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                        <div className="Order-Table_item heading fw700">Item</div>
                        <div className="Order-Table_item heading fw700">Qty.</div>
                        <div className="Order-Table_item heading fw700">Price</div>
                        <div className="Order-Table_item heading fw700">Discount</div>
                        <div className="Order-Table_item heading fw700">Total</div>
                        <div className="Order-Table_item heading fw700">Date</div>
                        <div className="Order-Table_item heading fw700 sticky-column tar">Manage</div>
                      </div>
                      {ordersData?.isLoading &&
                        <Skeletons />
                      }
                      {orders?.order_items?.map((item, i) =>
                        <div className="Order-Table_subcard">
                          <div className="Order-Table_item text">
                            <div>{item.item} </div>
                            <div><small>{item.description}</small></div>
                          </div>
                          <div className="Order-Table_item text">{item.quantity}</div>
                          <div className="Order-Table_item text">
                            <div>
                              <strong> {priceSplitter(Number(item.price) + Number(item.addon))}</strong>
                            </div>
                            {item.addon > 0 &&
                              <>
                                <div><small><Tag color={'#2db7f5'}>Added +{priceSplitter(item.addon)}</Tag></small></div>
                              </>
                            }
                          </div>
                          <div className="Order-Table_item text">{item.discount}</div>
                          <div className="Order-Table_item text">
                            <div>
                              <strong>{priceSplitter(item.total)}</strong>
                            </div>

                            {item.tax === 'GST' &&
                              <div><small><Tag color={'#2db7f5'}> inclusive GST(18%)</Tag></small></div>
                            }
                            {item.tax === 'VAT' &&
                              <div><small><Tag color={'#2db7f5'}>inclusive VAT(5%)</Tag></small></div>
                            }
                          </div>
                          <div className="Order-Table_item text">
                            {moment(item.createdAt).local().format("MMM Do YY, h:mm")}
                          </div>
                          <div className="Order-Table_item text sticky-column">
                            <div className="dFlex center nowrap jcr">

                              <a className="mr10" onClick={e => e.preventDefault()}>
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={open ? 'long-menu' : undefined}
                                  aria-expanded={open ? 'true' : undefined}
                                  aria-haspopup="true"
                                  onClick={() => handleOpenEdit({
                                    "status": true,
                                    "orderItem": item.item,
                                    "orderItemDescription": item.description,
                                    "orderItemQuantity": item.quantity,
                                    "orderItemAddon": item.addon,
                                    "orderItemDiscount": item.discount,
                                    "orderItemPrice": item.price,
                                    "orderItemTax": item.tax,
                                    "orderItemId": item.id
                                  })}
                                >
                                  <EditFilled style={{ fontSize: '18px' }} />
                                </IconButton>
                              </a>


                              {deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id ?
                                <div className="bouncing-loader">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                                : 
                                <a onClick={e => e.preventDefault()}>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={() => deleteCampaign({ id: item.id, userId: userId, userToken: userToken, admin: admin })}
                                  >
                                    <DoDisturbOnIcon style={{ fontSize: '22px', color: '#ea8087' }} />
                                  </IconButton>
                                </a>
                              }



                            </div>
                          </div>
                        </div>
                      )}


                    </div>
                    {orders?.order_items?.length === 0 &&
                      <h1 className="Order-no_data_found">Not Data Found!</h1>
                    }
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

  );
}

export default ManageOrder;
