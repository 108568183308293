import React, { Component, useState } from 'react';
import { Redirect, Link } from "react-router-dom";
import HeaderMenu from '../components/HeaderMenu'
import './index.scss';
import { useSelector } from "react-redux";
function Private(props) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);

  const Cmp = props.cmp;
  const [collapsed, setStatesss] = useState(false)
  function toggleCollapsed() {
    setStatesss(!collapsed)
  };

  return (
    <div className="ST-main-body">
      <HeaderMenu collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div className={`ST-body ${collapsed && 'bodyCollapsed'}`}>
        {userToken ? <Cmp /> : <Redirect to="login"></Redirect>}
      </div>
    </div>
  );
}

export default Private;
