import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  userId: localStorage.getItem("userId") ? localStorage.getItem('userId') : '',
  userEmail: localStorage.getItem("userEmail") ? localStorage.getItem('userEmail') : '',
  userToken: localStorage.getItem("userToken") ? localStorage.getItem('userToken') : '',
  userName: localStorage.getItem("userName") ? localStorage.getItem('userName') : '',
  admin: localStorage.getItem("admin") ? localStorage.getItem('admin') : false,
  status: localStorage.getItem("status") ? localStorage.getItem('status') : false,
};

const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addToCart(state, action) {
      state.userId = action.payload?.userId
      state.userEmail = action.payload?.userEmail
      state.userToken = action.payload?.userToken
      state.userName = action.payload?.userName
      state.admin = action.payload?.admin
      state.status = action.payload?.status
      localStorage.setItem("userId", state.userId);
      localStorage.setItem("userToken", state.userToken)
      localStorage.setItem("userEmail", state.userEmail)
      localStorage.setItem("userName", state.userName)
      localStorage.setItem("admin", state.admin)
      localStorage.setItem("status", state.status)
    },
    removeToCart(state, action) {
      state.userId = ''
      state.userEmail = ''
      state.userToken = ''
      state.userName = ''
      state.admin = false
      state.status = false
      localStorage.setItem("userId", state.userId);
      localStorage.setItem("userToken", state.userToken)
      localStorage.setItem("userEmail", state.userEmail)
      localStorage.setItem("userName", state.userName)
      localStorage.setItem("admin", state.admin)
      localStorage.setItem("status", state.status)
    },
    addToLanguage(state, action) {
      state.languageCode = action.payload
      localStorage.setItem("languageCode", state.languageCode)
    },
    removeToLanguage(state, action) {
      state.languageCode = ''
      localStorage.setItem("languageCode", state.languageCode)
    },

  },
  extraReducers: {
  }
});

export const { addToCart, removeToCart, addToLanguage, removeToLanguage } = authReducer.actions;

export default authReducer.reducer;
