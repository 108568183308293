import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query';
import { ordersApi } from '../services/orders';
import { productsApi } from '../services/products';
import { departmentsApi } from '../services/departments';
import { regionsApi } from '../services/regions';
import { nationalityApi } from '../services/nationalities';
import { suppliersApi } from '../services/suppliers';
 


import { sessionsApi } from '../services/session';
import { UsersApi } from '../services/users';

import authReducer from "../reducers/authReducer";

export const store = configureStore({
  // reducerPath and reducer are created for us, which we can pass straight into the reducer parameter of configureStore.
  reducer: {
    auth: authReducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [departmentsApi.reducerPath]: departmentsApi.reducer,
    [regionsApi.reducerPath]: regionsApi.reducer,
    [nationalityApi.reducerPath]: nationalityApi.reducer,
    [suppliersApi.reducerPath]: suppliersApi.reducer,
     

    [sessionsApi.reducerPath]: sessionsApi.reducer,
    [UsersApi.reducerPath]: UsersApi.reducer
  },

  // middleware is also created for us, which will allow us to take advantage of caching, invalidation, polling, and the other features of RTK Query.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(ordersApi.middleware).concat(productsApi.middleware).concat(departmentsApi.middleware).concat(regionsApi.middleware).concat(nationalityApi.middleware).concat(suppliersApi.middleware).concat(sessionsApi.middleware).concat(UsersApi.middleware)
})

 

// It will enable to refetch the data on certain events, such as refetchOnFocus and refetchOnReconnect.
setupListeners(store.dispatch)
 