import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import './index.scss';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from 'react-redux'

import { useGetUserQuery } from '../../services/users';

const KEY = "ADD_PRODUCTS";
function Profile() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);


  const userInfo = useGetUserQuery({
    userId: userId, userToken: userToken, admin: admin
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const user = userInfo?.data && userInfo?.data?.user;


  return (
    <div className="Product-style">



      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="Product-Top_navbar">
              <h4>Profile</h4>
            </div>
            




            <div className="Product-Table_view">
              <div className="Product-Table_card">

                <div className="Product-Table_subcard">
                  <div className="Product-Table_item" style={{ fontWeight: 700, width: '50%' }}>Name</div>
                  <div className="Product-Table_item" style={{width: '50%'}}>
                    {userInfo?.isLoading ? <Skeleton variant="rect" /> :
                      <span>{user?.name}</span>
                    }
                  </div>
                </div>
                <div className="Product-Table_subcard">
                <div className="Product-Table_item" style={{ fontWeight: 700, width: '50%' }}>Email</div>
                  <div className="Product-Table_item" style={{width: '50%'}}>
                    {userInfo?.isLoading ? <Skeleton variant="rect" /> :
                      user?.email
                    }
                  </div>
                </div>
                <div className="Product-Table_subcard">
                <div className="Product-Table_item" style={{ fontWeight: 700, width: '50%' }}>Phone</div>
                  <div className="Product-Table_item" style={{width: '50%'}}>
                    {userInfo?.isLoading ? <Skeleton variant="rect" /> :
                      user?.phone
                    }
                  </div>
                </div>

              </div>
            </div>






          </div>
        </div>
      </div>



    </div>
  );
}

export default Profile;
