import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import './index.scss';
import { useDispatch, useSelector } from "react-redux";

function Dashboard(props) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);

  const location = useLocation();
  return (
    <div className="dashboardStyle">
      {/* <div className="card card-dashboard-seven"> 
      <div className="db_welcome-msg">
        <div>
          <h2>Hi, {userName} welcome!</h2>
          <p>Your sales monitoring dashboard.</p>
        </div>
      </div>

           
          <div className="card-body-db">
            <div className="row">
              <div className="col-6 col-lg-3 seven-content-db">
                <label>Total Counts</label>
                <h2>110,000</h2> 
                  <span><strong>2.00%</strong> (30 days)</span>  
              </div>
              <div className="col-6 col-lg-3 seven-content-db">
                <label>Total Cost</label>
                <h2><span>$</span>523,200</h2> 
                  <span><strong>12.09%</strong> (30 days)</span> 
              </div>
              <div className="col-6 col-lg-3 seven-content-db">
                <label>Total Revenue</label>
                <h2><span>$</span>753,098</h2> 
                  <span><strong>0.51%</strong> (30 days)</span> 
              </div>
              <div className="col-6 col-lg-3 seven-content-db">
                <label>Total Profit</label>
                <h2><span>$</span>331,886</h2> 
                  <span><strong>5.32%</strong> (30 days)</span> 
              </div>
            </div> 
          </div> 
        </div> */}


      <div className='container'>
        <div className="row">
          <div className="col-6 col-lg-3">
            <div className='dashboard-card2'>
              <label>Total</label>
              <h2>000,000</h2>
              <span><strong>2.00%</strong> (30 days)</span>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className='dashboard-card2'>
              <label>On-Hold</label>
              <h2><span>$</span>000,000</h2>
              <span><strong>12.09%</strong> (30 days)</span>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className='dashboard-card2'>
              <label>Cancelled</label>
              <h2><span>$</span>000,000</h2>
              <span><strong>0.51%</strong> (30 days)</span>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className='dashboard-card2'>
              <label>In-Progress</label>
              <h2><span>$</span>000,000</h2>
              <span><strong>5.32%</strong> (30 days)</span>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default Dashboard;
