import React, { useState, useEffect, Fragment } from 'react';
import './index.scss';
import { useSelector } from "react-redux";

function Accounts() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);

  return (
    <div className="Product-style">

    </div>
  );
}

export default Accounts;
