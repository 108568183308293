import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import { message, Switch as Clickable, Tag } from "antd";
import "antd/dist/antd.css";
import { useGetAllProductQuery, useUpdateProductMutation, useDeleteProductMutation } from '../../services/products';

import { useSelector } from "react-redux";
import Skeletons from './Skeletons'
import moment from 'moment'
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "../Pagination";
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
const KEY = "ADD_PRODUCTS";

function Products() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const productsInfo = useGetAllProductQuery({
    page: page, size: limit, searchquery: getSearchQuery, userId: userId, userToken: userToken,
    admin: admin
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const productsData = productsInfo.data && productsInfo.data?.products;
  const pagination = productsInfo.data && productsInfo.data?.pagination;

  const [getChecked, setChecked] = useState(true)
  const onChange = (checked) => {
    setChecked(false)
  };

  const [editData, updateresult] = useUpdateProductMutation()
  const [deleteList, deleteresult] = useDeleteProductMutation()
  return (
    <div className="Order-style">

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="Order-Top_navbar">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4>Product List</h4>
                {(productsInfo?.isFetching) && (!productsInfo?.isLoading) &&
                  <div className="bouncing-loader" style={{ paddingLeft: '15px', paddingTop: '4px' }}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                }
              </div>
              <div className="dFlex center nowrap">

                <div className="table_search_card">
                  <input className="form-control table_search" placeholder="Search..." onChange={(e) => searchQuery(e.target.value)} />
                  <SearchIcon className="table_search_icon" />
                </div>

              </div>
            </div>

            <div className="Order-Pagination">
              <Pagination page={page} limit={limit} pagination={pagination} />
            </div>

            <div className="Order-Table_view">
              <div className="Order-Table_card">
                <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                  <div className="Order-Table_item heading fw700">Sale</div>
                  <div className="Order-Table_item heading fw700">Order</div>
                  <div className="Order-Table_item heading fw700">Supplier</div>
                  <div className="Order-Table_item heading fw700">Department</div>
                  <div className="Order-Table_item heading fw700">Nationality</div>
                  <div className="Order-Table_item heading fw700">Price</div>
                  <div className="Order-Table_item heading fw700">Date</div>
                  <div className="Order-Table_item heading fw700 sticky-column fw700 tar">Status</div>
                </div>
                {productsInfo?.isLoading &&
                  <Skeletons />
                }
                {productsData?.map((item, i) =>
                  <div className="Order-Table_subcard">
                    <div className="Order-Table_item text">
                      <Clickable defaultChecked={item.sale ? true : false}
                        onClick={() => editData({
                          sale_switch: 'sale_switch', id: item.id, sale: item.sale ? false : true, userId: userId, userToken: userToken,
                          admin: admin
                        })}
                      />
                    </div>
                    <div className="Order-Table_item text">{item.product}</div>
                    <div className="Order-Table_item text">{item.supplier?.name}</div>
                    <div className="Order-Table_item text">{item.department}</div>
                    <div className="Order-Table_item text">{item.nationality}</div>
                    <div className="Order-Table_item text">{item.sale_price}</div>
                    <div className="Order-Table_item text">
                      {moment(item.createdAt).local().format("MMM Do YY, h:mm")}
                    </div>
                    <div className="Order-Table_item text sticky-column">
                      <div className="dFlex center nowrap jcr">


                        {item.status === 0 ?
                          <small className="dFlex">
                            {deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id ?
                              <div className="bouncing-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              :
                              <a className='mr10' onClick={() => deleteList({
                                activate: 'activate',
                                id: item.id,
                                userId: userId,
                                userToken: userToken,
                                admin: admin
                              })}>
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={open ? 'long-menu' : undefined}
                                  aria-expanded={open ? 'true' : undefined}
                                  aria-haspopup="true"
                                >
                                  <DoDisturbOnIcon style={{ fontSize: '22px', color: '#ea8087', }} />
                                </IconButton>
                              </a>
                            }
                          </small> :
                          <small className="dFlex">
                            {deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id ?
                              <div className="bouncing-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              :
                              <a className='mr10' onClick={() => deleteList({
                                deactivate: 'deactivate',
                                id: item.id,
                                userId: userId,
                                userToken: userToken,
                                admin: admin
                              })}>
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={open ? 'long-menu' : undefined}
                                  aria-expanded={open ? 'true' : undefined}
                                  aria-haspopup="true"
                                >
                                  <CheckCircleIcon style={{ fontSize: '22px', color: '#7fc687', }} />
                                </IconButton>
                              </a>
                            }
                          </small>
                        }
                      </div>
                    </div>
                  </div>
                )}


              </div>
              {productsData?.length === 0 &&
                <h1 className="Order-no_data_found">Not Data Found!</h1>
              }
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Products;
